
  /* @font-face { font-family: "Somerton Dense"; src: url('./somerton_dense/Somerton Dense df.otf'); }  */
  @font-face { font-family: "Poppins"; src: local("Poppins") url('./Fonts/Poppins-Regular.woff') format('woff'); font-display: swap;} 
  @font-face { font-family: "monoSpaceBold"; src: local("monoSpaceBold") url('./Fonts/SpaceMono-Bold.woff') format('woff'); font-display: swap;} 
  @font-face { font-family: "casadia"; src: local("casadia") url('./Fonts/Cascadia.woff') format('woff'); font-display: swap;}
  @font-face { font-family: "Courier"; src: local("Courier") url('./Fonts/Courier-BoldRegular.woff') format('woff'); font-display: swap;} 

    html{
        scroll-behavior: smooth;
    }
    body{
        /* background-Color: #080027; */
        /* background: radial-gradient(at 64% 72%, hsla(110,87%,79%,0.1) 0px, transparent 50%),radial-gradient(at 98% 83%, hsl(203deg 89% 88%) 0px, transparent 50%),radial-gradient(at 94% 10%, hsla(239,50%,74%,0.1) 0px, transparent 50%),radial-gradient(at 96% 33%, hsla(189,78%,78%,0.1) 0px, transparent 50%),radial-gradient(at 47% 3%, hsla(179,73%,64%,0.1) 0px, transparent 50%),radial-gradient(at 8% 2%, hsla(127,91%,66%,0.1) 0px, transparent 50%),radial-gradient(at 4% 99%, hsla(72,87%,69%,0.1) 0px, transparent 50%); */
        background: radial-gradient(at 64% 72%,hsl(110deg 87% 79%/10%) 0,transparent 50%),radial-gradient(at 98% 83%,hsl(203deg 89% 88%) 0,transparent 50%),radial-gradient(at 94% 10%,hsl(239deg 50% 74%/30%) 0,transparent 50%),radial-gradient(at 96% 33%,hsl(189deg 78% 78% / 20%) 0,transparent 50%),radial-gradient(at 47% 3%,hsl(179deg 73% 64% / 14%) 0,transparent 50%),radial-gradient(at 8% 2%,rgb(89 247 108 / 15%) 0,transparent 50%),radial-gradient(at 4% 99%,hsla(72,87%,69%,.1) 0,transparent 50%);
        
    }
    .fixed-top {
        z-index: 999 !important;
    }
    .css-nvf14r-ToastContainer {
        z-index: 10000 !important;
    }
    .poppins {
        font-family: "Poppins";
    }
    .back-color{
        color: #080027 !important;
    }
    /* .backgr-color{
        background-color: #080027 !important;
    } */
    h1{
        color: white; 
        font-weight: 700;
        font-family: Courier;
    }
    h2{
        color: white; 
        font-weight: 700;
        font-family: Courier;
    }
    h3{
        color: white; 
        font-weight: 700;
        font-family: Courier;
    }
    h4{
        color: white; 
        font-weight: 700;
        font-family: Courier;
    }
    h5{
        color: white; 
        font-weight: 700;
        font-family: Courier;
    }
    h6{
        color: white; 
        font-weight: 700;
        font-family: Courier,serif;
    }
   
   
    nav{
        background-repeat: no-repeat !important;
    background-size: auto;
    background-size: cover;
    }

    /* .fa-linkedin{
        color: #0c66c2;
    } */
    #logo{
        max-width: 160px;
    }
    #nav-ul{
        list-style: none;
        display: flex;
        justify-content: center;
    }
    #nav-ul li{
        padding: 5px;
        font-weight: 700;
    }
    #nav-twitter{
        width: 30px;
        padding: 5px;
    }
    #nav-discord{
        width: 30px;
        padding: 5px;
    }    
  
    .jc-center{
        justify-content: center;
    }
    .jc-end{
        justify-content: end;
    }
    .text-end{
        text-align: end;
    }
    .darkMode .nav-link{
        color: white !important;
    }
    .darkMode {
        background: #010010;
    }
    .nav-link{
        color: #030303 !important;
        font-size: 14px;
        font-family: Courier;
        transition: all 0.3s;
        background: /* gradient can be an image */ linear-gradient( to left, #5C0CEC 0%, #1FCBFF 100% ) left bottom transparent no-repeat;
        background-size: 0% 2px;
        cursor: pointer;

    }
    .nav-link:hover,.nav-link.active {
        background: /* gradient can be an image */ linear-gradient( to left, #5C0CEC 0%, #1FCBFF 100% ) left bottom transparent no-repeat;
        background-size: 100% 2px;
        transition: all 0.3s;
    }

    .darkNav .nav-link{
        color: #fff !important;
        font-size: 14px;
        font-family: Courier;
        transition: all 0.3s;
        background: /* gradient can be an image */ linear-gradient( to left, #F4318D 0%, #F4318D 100% ) left bottom transparent no-repeat;
        background-size: 0% 2px;
        cursor: pointer;

    }
    .darkNav .nav-link:hover,.darkNav .nav-link.active {
        background: /* gradient can be an image */ linear-gradient( to left, #F4318D 0%, #F4318D 100% ) left bottom transparent no-repeat;
        background-size: 100% 2px;
        transition: all 0.3s;
        color: white !important;
    }
    .darkMode .navbar-light .navbar-toggler-icon {
        filter: invert(1);
    }
 
 

.flex-center{
    display: flex;
    justify-content: center;
}
.wrap{
    white-space: pre-wrap;      /* CSS3 */   
   white-space: -moz-pre-wrap; /* Firefox */    
   white-space: -pre-wrap;     /* Opera <7 */   
   white-space: -o-pre-wrap;   /* Opera 7 */    
   word-wrap: break-word;
}

#scrollToTop{
    background: linear-gradient(110deg, #5C0CEC 0%, #1FCBFF 100%);
            color: white;
            border: none;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            position: fixed;
            right: 20px;
            bottom: 20px;
            /* opacity: 0; */
            font-size: 20px;
            display: none;
            transition: all 0.3s;
            z-index: 1000;
}
#scrollToTop:focus{
    outline: none;
}
#scrollToTop:active{
    background-color: white;
}
#scrollToTop:hover{
    box-shadow: 0px 6px 18px -5px black;
}
#scrollToTop::before {
    content: "";
    border: 2px solid #37A3FE;
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    animation: zoomBig 2s linear infinite;
    -webkit-animation-delay: .85s;
    animation-delay: .85s;
}
@keyframes zoomBig {
    0%{
        width: 55px;
        height: 55px;
        opacity: 1;
    }
    50%{
        width: 70px;
        height: 70px;
        opacity: 0.8;
    }
    100%{
        width: 85px;
        height: 85px;
        opacity: 0;
    }
}
#scrollToTop::after {
    content: "";
    border: 2px solid #37A3FE;
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    animation: zoomBig1 2s linear infinite;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
@keyframes zoomBig1 {
    0%{
        width: 55px;
        height: 55px;
        opacity: 1;
    }
    50%{
        width: 70px;
        height: 70px;
        opacity: 0.8;
    }
    100%{
        width: 85px;
        height: 85px;
        opacity: 0;
    }
}

.ourCompanySec {
    position: relative;
    padding: 100px 0;
}
.ourCompanySec .effect {
    position: absolute;
    top: 400px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    width: 100%;
}
.ourCompanySec .comp{
    color: #8f8f8f;
    font-size: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.43;
    margin-top: -5px;
    text-align: center;
    margin-bottom: 20px;
}
.darkMode .ourCompanySec .compDesc {
    color: white;
}
.ourCompanySec .compDesc {
    font-weight: 700;
    color: #333333;
    text-align: center;
    margin-bottom: 50px;
}
.ourCompanySec .compDesc span{
    color: #3364FB;
}
.ourCompanySec .col1 {
    width: max-content;
    margin: auto;
}
.ourCompanySec .col1 {
    width: max-content;
    margin: auto;
}
.ourCompanySec .col1 .expMain{
    position: relative;
    font-size: 12px;
    line-height: 2em;
    letter-spacing: 2px;
    color: #ababab;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: max-content;
}
.ourCompanySec .col1 .expNum{
    font-size: 180px;
    line-height: .8;
    font-weight: 900;
    margin: 0 5px -5px -5px;
    color: #3e72f2;
}
.darkMode .ourCompanySec .col1 p{
    color: white;
}
.darkMode .ourCompanySec .col2 p{
    color: white;
}
.darkMode .ourCompanySec .col1 h6{
    color: white;
}
.ourCompanySec .col1 p{
    font-size: 12px;
    line-height: 2em;
    letter-spacing: 2px;
    color: #333333;
    max-width: 110px;
    margin: 0;
}
.ourCompanySec .col1 h6{
    color: #333333;
    font-weight: 600;
    margin-top: 30px;
}
.ourCompanySec .col2{
    max-width: 500px;
}
.ourCompanySec .col2 p{
    font-size: 18px;
    color: #333333;
}
.ourCompanySec .col2 button{
    color: #3e72f2;
    padding: 0;
    font-size: 15px;
}

.becomePartnerSec {
    padding-top: 30px;
    padding-bottom: 100px;
}
.darkMode .becomePartnerSec .partnerCard {
    box-shadow: 0px 0px 23px 0px rgb(86 172 255 / 42%);
    color: white;
    background: rgba(0,0,0,0.4);
}
.becomePartnerSec .partnerCard{
    position: relative;
    top: 0;
    -webkit-box-shadow: 0 0 40px 5px rgb(51 51 51 / 10%);
    box-shadow: 0 0 40px 5px rgb(51 51 51 / 10%);
    background: #fff;
    border-radius: 5px;
    padding: 50px 0px 0px 0px;
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    text-align: center;
    transition: all 0.3s;
}
.becomePartnerSec .partnerCard.fb:hover {
    top: -10px;
    transition: all 0.3s;
}
.becomePartnerSec .partnerCard .imgDiv {
    position: relative;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin: 0 auto 2px;
    text-align: center;
}
.becomePartnerSec .partnerCard .imgDiv img {
    max-width: 100%;
}
.darkMode .becomePartnerSec .partnerCard h5{
    color: white;
}
.becomePartnerSec .partnerCard h5{
    color: #333333;
    /* margin-top: 15px; */
    font-weight: bold;
    margin: 20px 0;
}
.darkMode .becomePartnerSec .partnerCard p{
    color: white;
}   
.becomePartnerSec .partnerCard p{
    color: #696969;
    max-width: 225px;
    font-size: 15px;
    margin: auto;
}
.becomePartnerSec .partnerCard .discover{
    height: 56px;
    line-height: 55px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border-top: 1px solid #eee;
    color: #086AD8;
    display: block;
    margin-top: 30px;
}

.brochureSec {
    position: relative;
    padding: 40px 0;
    background-color: #f8f8f8;
    background-image: url(./images/brochure.webp);
    background-repeat: no-repeat;
    background-position: center right 40px;
    margin: 100px 0;
}
.darkMode .brochureSec {
    background: url("./images/brochure.webp"), linear-gradient( rgba(0,0,0,0.1), rgba(0,0,0,0.1)  );

}
.brochureSec .col1 h6 {
    color: #ababab;
    font-weight: bold;
    margin-bottom: 20px;
}
.darkMode .brochureSec .col1 h2 {
    color: white;
}
.brochureSec .col1 h2 {
    color: #333333;
    font-weight: bold;
}
.brochureSec .col1 h2 span{
    color: #086AD8;
    font-weight: bold;
}
.brochureSec .col1 .downloadBtn{
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0em;
    line-height: 54px;
    height: 54px;
    border-style: solid;
    border-radius: 5px;
    border-color: transparent;
    padding: 0 36px;
    text-align: center;
    color: #ffffff;
    background: #086AD8;
    border-width: 1px;
    margin-top: 25px;
}
.brochureSec .imgDiv {
    text-align: center;
}

.r1c1 {
    margin-right: 320px !important ;
    margin-left: 320px !important;
}
.r2c1 {
    margin-right: 160px !important ;
    margin-left: 160px !important;
}

@media(max-width:992px){
    .nav-link {
        width: max-content;
    }
    .brochureSec .col1 {
        text-align: center;
    }
    .brochureSec .col1 h2 {
        font-size: 30px;
    }
    .ourCompanySec .col2 {
        max-width: 100%;
        margin-top: 30px;
    }
    .navbar-nav {
        margin-top: 20px;
    }
    .pt-86 {
        padding-top: 80px;
    }
    .teamSec .content {
        margin-bottom: 140px !important;
        width: 100% !important;
    }
  
    .toggle-icon-custom{
        flex: 0 0 25%;
        max-width: 25%;
        position: absolute;
        right: 30px;
        top: 20px;
    }
    .connect-wallet-small{
        position: absolute !important;
        right: 35%;
    }
    .collapse-nav{
    position: relative;
    /* background-color: white; */
    z-index: 10;
    }
    .icon-div{
    display: none;
    }
 
}
.header{
    /* background: #EEF2FF; */
    /* background: linear-gradient(180deg, #EEF2FF 0%, #fff 100%); */
    /* background: linear-gradient(180deg, #d9e0f7 0%, #fff 100%); */
}
.homeHeader {
    min-height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    /* background: radial-gradient(at 64% 72%, hsla(110,87%,79%,0.1) 0px, transparent 50%),radial-gradient(at 98% 83%, hsl(203deg 89% 88%) 0px, transparent 50%),radial-gradient(at 94% 10%, hsla(239,50%,74%,0.1) 0px, transparent 50%),radial-gradient(at 96% 33%, hsla(189,78%,78%,0.1) 0px, transparent 50%),radial-gradient(at 47% 3%, hsla(179,73%,64%,0.1) 0px, transparent 50%),radial-gradient(at 8% 2%, hsla(127,91%,66%,0.1) 0px, transparent 50%),radial-gradient(at 4% 99%, hsla(72,87%,69%,0.1) 0px, transparent 50%); */
    /* background: radial-gradient(at 64% 72%, hsla(110,87%,79%,0.1) 0px, transparent 50%),radial-gradient(at 98% 83%, hsl(203deg 87% 91%) 0px, transparent 50%),radial-gradient(at 94% 10%, hsla(239,50%,74%,0.1) 0px, transparent 50%),radial-gradient(at 96% 33%, hsla(189,78%,78%,0.1) 0px, transparent 50%),radial-gradient(at 47% 3%, hsla(179,73%,64%,0.1) 0px, transparent 50%),radial-gradient(at 8% 2%, hsla(127,91%,66%,0.1) 0px, transparent 50%),radial-gradient(at 4% 99%, hsla(72,87%,69%,0.1) 0px, transparent 50%)s; */
}
.homeHeader .effect {
    position: absolute;
    top: -497px;
    right: -900px;
}
.homeHeader .effect1 {
    position: absolute;
    top: -497px;
    left: -900px;
}
.header .sigupBtn {
    /* background: linear-gradient(134.29deg, #E65528 0%, #6F2874 100%); */
    background: #3364FB;
    color: white;
    margin-left: 30px;
    padding: 5px 40px;
    border-radius: 20px;
    border: 2px solid #3364FB;
    box-shadow: 0 1px 14px -2px rgb(62 114 242);
    transition: all 0.3s;
}
.header .sigupBtn:hover{
    background: white;
    color: #3364FB;
  
    border: 2px solid #3364FB;
    transition: all 0.3s;
}

.header.darkNav .sigupBtn{
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    /* background: #3364FB; */
    background-size: 100% 100%;
    color: white;
    margin-left: 30px;
    padding: 5px 40px;
    border-radius: 20px;
    outline: 2px solid transparent; 
    border: none;
    /* box-shadow: 0 1px 14px -2px rgb(62 114 242); */
    transition: all 0.3s;
}
.header.darkNav .sigupBtn:hover{
    background-size: 0% 100%;
    outline: 2px solid #EC5623;
    transition: all 0.3s;
}
.lightMode .header.darkNav .sigupBtn:hover{
    background-size: 0% 100%;
    outline: 2px solid #EC5623;
    transition: all 0.3s;
    color: black;
}

.header .sigupBtn:hover img{ 
    filter: invert(1);
}
.header .headerRow {
    align-items: center;
}
.header .headerRow .navCard{
    max-width: 100%;
}
.darkMode .header .headerRow h1 {
    color: white;
}
.header .headerRow h1 {
    color: black;
    font-size: 84px;
    text-transform: uppercase;
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: 700;
    line-height: 1;
}
.header .headerRow h1 span {
    display: none;
}
.header .headerRow h6 {
    font-size: 25px;
    margin-top: 16px;
    margin-bottom: 35px;
}
.header .headerRow p {
    font-weight: bold;
    margin: 35px 0 25px 0;

}
.darkMode .header .headerRow .arrowDownImg {
    filter: invert(1);
}
.header .headerRow .arrowDownImg {
    width: 115px;
    margin: auto;
    margin-top: 50px;
}
.header .headerRow .arrowDownImg svg #wheel{
    animation: wheel ease 2s infinite;
    top: 0;
}
.filter-invert {
    filter: invert(1);
}
@keyframes wheel {
    0%{
        top: 0;
    }
    50%{
        top: 2px;
    }
    100%{
        top: 20px;
    }
  }
.header .headerRow h6,p {
    color: #3364FB;
}
.header .headerRow .merchartsMain {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 455px;
    margin: auto;
}
.header .headerRow .merchartsMain img{
    max-height: 40px;
}

.homeSignBtn {
    width: max-content;
    margin: auto;
    position: relative;
}
.homeSignBtn .shadow{
    position: absolute;
    left: -4px;
    right: -3px;
    bottom: -10px;
    top: -3px;
    background: gray;
    z-index: -1;
    border-radius: 20px;
    background: linear-gradient(90deg, #6671ee 0%, #1FCBFF 100%);
    filter: blur(28px);
}
.whatWeDo {
    padding: 0px 40px;
    background: linear-gradient(90deg, #5C0CEC 0%, #1FCBFF 100%);
    border-radius: 10px;
    margin: 130px 0;
    position: relative;
    transition: all 0.3s;
}
.whatWeDo .grad{
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    background: linear-gradient(90deg, #6671ee 0%, #1FCBFF 100%);
    filter: blur(18px);
    z-index: -1;
}
.whatWeDo h2 {
    font-size: 57px;
    color: white;
    font-weight: 700;
}
.whatWeDo .whatWeDoMain {
    margin-left: 50px;
}
.whatWeDo .whatRow {
    align-items: center;
    position: relative;
    z-index: 1;
}

.whatWeDo .getBtn {
    background-color: white;
    border: 2px solid white;
    padding: 9px;
    max-width: 195px;
    width: 100%;
    border-radius: 20px;
    color: black;
    font-weight: 500;
    margin-top: 20px;
    transition: all 0.3s;
}
.whatWeDo .getBtn:hover {
    border: 2px solid white;
    background: #5572f2;
    color: white;
    transition: all 0.3s;
}

.whatWeDo .weDoCard {
    position: relative;
    background-color: white;
   border-radius: 10px;
    text-align: center;   
    padding: 30px 10px; 
    /* margin-bottom: 20px; */
    box-shadow: 0 0 20px rgb(8 0 17 / 25%);
    height: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    top: 0px;

}
.whatWeDo .weDoCard:hover {
    transition: all 0.3s;
    position: relative;
    top: -10px;
    box-shadow: 0px 11px 20px -1px rgb(8 0 17 / 25%);

}
.whatWeDo .weDoCard p{
    color: black;
    font-weight: 500;  
    margin-bottom: 0;
    margin-top: 5px;
}
.whatWeDo .col1 {
    position: relative;
    top: 60px;
}
.whatWeDo .col2 {
    position: relative;
    top: -60px;
}
.whatWeDo .col3 {
    position: relative;
    top: 60px;
}
.whatWeDo .whatWeDoRow {
    align-items: center;
}
.whatWeDo .WhatBackground{
    position: absolute;
    bottom: 0;
    max-width: 100%;
    height: 100%;
}
.specialServiceSec {
    margin: 150px 0 100px 0;
    color: black;
    position: relative;
}
.specialServiceSec .effect {
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

.darkMode .specialServiceSec h2 {
    color: white;
}
.darkMode .ourProject h2 {
    color: white;
}
.specialServiceSec h2 {
    color: black;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 50px;
}
.darkMode .specialServiceSec .serviceCard h6 {
    color: #fff;
}
.darkMode .specialServiceSec .serviceCard .desc {
    color: #fff;
}
.specialServiceSec .serviceCard h6 {
    color: black;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1.3;
    word-break: break-word;
}
.specialServiceSec .innerContent {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    
}
.specialServiceSec .serviceCard{
    box-shadow: 0 0 13px rgb(8 0 17 / 25%);
    border-radius: 15px;
    padding: 25px;
    height: 285px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    position: relative;
    top: 0px;
    transition: all 0.3s;

}
.darkMode .specialServiceSec .serviceCard {
    /* box-shadow: 0px 0px 23px 0px rgb(86 100 255 / 42%); */
    box-shadow: 0px 0px 23px 0px rgb(86 172 255 / 42%);
    padding: 30px;
    color: white;
    background: rgba(0,0,0,0.4);
}
.darkMode .specialServiceSec .serviceCard:hover {
    box-shadow: 0px 0px 23px 0px rgb(86 172 255 / 42%);
}
.specialServiceSec .serviceCard:hover {
    transition: all 0.3s;
    position: relative;
    top: -10px;
    box-shadow: 0px 11px 20px -1px rgb(8 0 17 / 25%);

}
.specialServiceSec .serviceCard .icon{
    width: 70px;
    margin-right: 17px;
}
.specialServiceSec .serviceCard .desc{
    color: #222A41;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* word-break: break-word; */
    max-height: 54px;
    display: -webkit-inline-box;
}
.specialServiceSec .serviceCard .btnDiv{
    display: flex;
    justify-content: space-between;
}
.specialServiceSec .serviceCard .btnDiv .demoBtnDiv{
    position: relative;
}
.specialServiceSec .serviceCard .btnDiv .demoBtnDiv .shadow{
    position: absolute;
    left: -2px;
    right: -2px;
    bottom: -2px;
    top: -2px;
    z-index: -1;
    border-radius: 20px;
    background: linear-gradient(90deg, #6671ee 0%, #1FCBFF 100%);
    filter: blur(4px);
}

.specialServiceSec .serviceCard .btnDiv .readMore{
    padding: 0;
    padding-bottom: 5px;
    font-weight: 700;
    font-size: 13px;
    background: /* gradient can be an image */ linear-gradient( to left, #5C0CEC 0%, #1FCBFF 100% ) left bottom #fff no-repeat;
    background-size: 100% 3px;
    transition: all 0.3s;
}
.specialServiceSec .serviceCard .btnDiv .readMore:hover {
    transition: all 0.3s;
    color: white;
    background-size: 100% 28px;
    /* padding: 0 10px; */
}
/* .specialServiceSec .serviceCard .btnDiv .getDemo:hover img{
    filter: invert(1);
} */
.specialServiceSec .serviceCard .btnDiv .getDemo:hover{
    background: transparent;
    color: #fff;
  
    border: 2px solid #3364FB;
    transition: all 0.3s;
}
/* .specialServiceSec .serviceCard .btnDiv .getDemo:hover+.shadow{
    background: linear-gradient(90deg, #6671ee 0%, #1FCBFF 100%);
    filter: blur(10px);
} */
.specialServiceSec .serviceCard .btnDiv .getDemo{
    color: white;
    padding: 4px 14px;
    border-radius: 20px;
    background-color: #3364FB;
    border: 2px solid #3364FB;

    font-weight: 700;
    font-size: 10px;
    display: flex;
    align-items: center;
}
 .slick-prev:before, .slick-next:before {
    color: #4072f2 !important;
}
.specialServiceSec .slick-next{
    transform: translate(-14px, -50%);
}
.specialServiceSec .slick-prev{
    transform: translate(14px, -50%);
}
.specialServiceSec .serviceCard .btnDiv .getDemo img{
    margin-left: 15px;
}
.darkMode .specialServiceSec .element1 {
    z-index: 0;
}
.specialServiceSec .element1 {
    position: absolute;
    right: 0;
    z-index: -1;
    bottom: 55px;
    width: 240px;
    opacity: 0.2;
}
.ourProject {
    margin: 50px 0;
}
.ourProject .overlay::before {
    /* background: transparent; */
    background: linear-gradient(to right,rgb(248 253 245),transparent);
    width: 70px;
}
.darkMode .ourProject .overlay::before {
    background: linear-gradient(to right,rgb(7 8 8),transparent);
}
.darkMode .ourProject .overlay::after {
    background: linear-gradient(to right,rgb(7 8 8),transparent);
}
.ourProject .overlay::after {
    /* background: transparent; */
    background: linear-gradient(to right,rgb(217 237 249),transparent);
    width: 70px;
}
.ourProject .marquee {
    justify-content: center;
}
.ourProject .projectCardMain{
    width: 290px;
    height: 129px;
    padding: 0 10px;
}
.darkMode .ourProject .projectCard{
    background-color: #ffffff80;
}
.ourProject .projectCard{
    border-radius: 10px;
    /* background-color: #EEF2FF; */
    background-color: #fff;
    padding: 15px;
    text-align: center;
    z-index: 11;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.ourProject .row1{
    justify-content: center;
    width: 870px;
}
.ourProject .row3{
    justify-content: center;
    width: 870px;
}
.ourProject .row2{
    justify-content: center;
    /* margin: 20px 0; */
    margin-top: 20px;
    margin-bottom: 20px;
}
.ourProject .projectCard img{
    max-width: 160px;
    max-height: 100px;
}
.ourProject h2{
    color: black;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 20px;
}
.backgroundGradient180 {
    background: linear-gradient(180deg, #5C0CEC 0%, #1FCBFF 100%);
}
.projectCounter {
    padding: 100px 0;
    margin: 120px 0;
    /* background-color: #fff; */
}
.projectCounter .shadowMain{
    position: relative;
}
.projectCounter .shadowMain .shadow{
    background: linear-gradient(90deg, #6671ee 0%, #1FCBFF 50%, #1FCBFF 100%);
    position: absolute;
    left: -3px;
    right: -2px;
    bottom: -2px;
    top: -2px;
    z-index: -1;
    /* filter: blur(13px); */
    filter: blur(30px);
}
.projectCounter .counterMain {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 16px;
    overflow: hidden;
    /* box-shadow: 0 0 20px rgb(8 0 17 / 25%); */
    /* max-width: 900px;
    margin: auto; */
}
.projectCounter .counterMain .imgDiv i{
    /* color: #4072f2; */
}
.projectCounter .counterMain .countCard{
    padding: 25px;
    background-color: #fff;
    width: 25%;
    text-align: center;
    /* background: linear-gradient(90deg, #eef2fd 0%, #ffffff 100%); */
}
.projectCounter .counterMain .countCard h3{
    color: black;
    font-weight: 700;
    font-size: 65px;
    margin: 10px 0;
}
.projectCounter .counterMain .countCard p{
    text-transform: uppercase;
    color: black;
    margin-bottom: 0;
}

.projectCounter1 {
    margin: 150px 0;
    /* background-color: #fff; */
}
.projectCounter1 .shadowMain{
    position: relative;
}
.projectCounter1 .shadowMain .shadow{
    /* background: linear-gradient(90deg, #6671ee 0%, #1FCBFF 50%, #1FCBFF 100%); */
    background: linear-gradient(90deg, #6671ee 0%, #6171ee 50%, #5e71ee 100%);
    position: absolute;
    left: -4px;
    right: -4px;
    bottom: -4px;
    top: -4px;
    z-index: -1;
    /* filter: blur(13px); */
    filter: blur(12px);
}

.projectCounter1 .counterMain {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 16px;
    overflow: hidden;
    /* box-shadow: 0 0 20px rgb(8 0 17 / 25%); */
    /* max-width: 900px;
    margin: auto; */
    background: linear-gradient(180deg, #5C0CEC 0%, #3e72f2 100%);
    padding: 35px 8px;
    border-radius: 20px;

}
.projectCounter1 .counterMain .imgDiv {
    margin-right: 20px;
}
.projectCounter1 .counterMain .imgDiv img{
    max-width: 65px;
}
.projectCounter1 .counterMain .imgDiv i{
    color: #fff;
}
.projectCounter1 .counterMain .countCard{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    /* background-color: #fff; */
    width: 25%;
    /* background: linear-gradient(90deg, #eef2fd 0%, #ffffff 100%); */
}
.projectCounter1 .counterMain .countCard h3{
    color: white;
    font-weight: 700;
    font-size: 40px;
    margin: 0;
}
.projectCounter1 .counterMain .countCard p{
    color: white;
    margin-bottom: 0;
}


.teamSec {
    position: relative;
    padding: 210px 0 150px 0;
}
.teamSec .effect {
    position: absolute;
    top: -100px;
    right: -600px;
}
.teamSec .content{
    width: 280px;
    margin: auto;
}
.darkMode .teamSec h2 {
    color: white;
}
.darkMode .teamSec p {
    color: white;
}
.teamSec h2 {
    color: black;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 15px;
}
.teamSec p {
    color: #222A41;
}
.teamSec .viewBtn {
    padding-bottom: 6px;
    font-weight: 700;
    font-size: 13px;
    background: /* gradient can be an image */ linear-gradient( to left, #5C0CEC 0%, #1FCBFF 100% ) left bottom transparent no-repeat;
    background-size: 35px 3px;
    color: black;
    text-transform: uppercase;
    margin-top: 50px;
    display: inline-block;
    transition: all 0.3s;
}
.teamSec .viewBtn:hover {
    transition: all 0.3s;
    text-decoration: none;
    background-size: 100% 3px;
}
.teamSec .imgMain {
    display: flex;
    justify-content: center;
}
.teamSec .imgMain .col1{
    padding: 0 10px;
    
    position: relative;
    top: -100px;
    width: 100%;

}
.teamSec .imgMain .col2{
    padding: 0 10px;
    width: 100%;
}
.teamSec .imgDiv {
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    max-height: 193.2px;
    overflow: hidden;
    border-radius: 14px;
    box-shadow: 0 0 13px rgb(8 0 17 / 13%);
    background: white;
}
.teamSec .imgMain img{
    max-width: 100%;
}
.teamSec .teamElement {
    position: absolute;
    width: 320px;
    top: -100px;
}


.teamSec .imgDiv {
    position: relative;
}
.teamSec .imgDiv .content1 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 55%;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
    background: rgba(0,0,0,0);
    transition: all 0.3s;

  }
  .teamSec .imgDiv .content1 h3 {
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
  .teamSec .imgDiv .content1 p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .teamSec .imgDiv .content1 a i{
      font-size: 18px;
  }
  .teamSec .imgDiv .content1 a {
    text-decoration: none;
    /* background: #002417; */
    color: #fff;
    padding: 5px;
    display: inline-block;
    transition: all 0.3s;
    transition-delay: 0s !important;

  }
  .teamSec .imgDiv .content1 a:hover {
      transition: all 0.3s;
      transform: scale(1.2) !important;
        transition-delay: 0s !important;

    
  }
  .lightMode .teamSec .imgDiv .content1 h3,
  .lightMode .teamSec .imgDiv .content1 p,
  .lightMode .teamSec .imgDiv .content1 a {
    
    color: #fff;
  }
  .teamSec .imgDiv .content1 h3,
  .teamSec .imgDiv .content1 p,
  .teamSec .imgDiv .content1 a {
    opacity: 0;
    transition: 0.5s;
    transform: translateY(20px);
    color: white;
  }
  .teamSec .imgDiv:hover .content1 h3 {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
  }
  .teamSec .imgDiv:hover .content1 {
    background: rgba(0,0,0,0.6);
    transition: all 0.3s;

  }
  .teamSec .imgDiv:hover .content1 p {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.5s;
  }
  .teamSec .imgDiv:hover .content1 a {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.7s;
  }
  .customerSec .overlay::before {
    background: transparent;
    }
    .customerSec .overlay::after {
        background: transparent;
    }
  

.customerSec {
    padding: 120px 0;
    background: linear-gradient(180deg, #5C0CEC 0%, #1FCBFF 100%);
    position: relative;
    z-index: 1;
}
.customerSec .grad{
    position: absolute;
    top: -12px;
    right: 0px;
    bottom: -4px;
    left: 0px;
    background: linear-gradient(180deg, #6470ed 0%, #1FCBFF 100%);
    z-index: -1;
    filter: blur(18px);
}
.customerSec .row {
    align-items: center;
}
.customerSec h2 {
    font-size: 60px;
    margin-bottom: 40px;
    font-weight: 700;
    position: relative;
    z-index: 1;
}
.customerSec .customerCard {
    background: white;
    padding: 20px;
    border-radius: 12px;
    max-width: 400px;
    position: relative;
    z-index: 1;
    transition: all 0.3s;
    position: relative;
    top: 0px;
}
.customerSec .customerCard:hover {
    transition: all 0.3s;
    position: relative;
    top: -10px;
    box-shadow: 0px 11px 20px -1px rgb(8 0 17 / 25%);
}
.customerSec .customerCard .desc{
    color: #5F5D83;
}
.customerSec .customerCard .profileDiv{
    display: flex;
    align-items: center;
}
.customerSec .customerCard .profileDiv img{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 10px;
}
.customerSec .customerCard .profileDiv .name{
    color: #312E81;
    margin: 0;
}
.customerSec .customerCard .profileDiv .designation{
    color: #312E81;
    margin: 0;
    font-size: 14px;
}
.customerSec .quoteImg {
    margin-bottom: 50px;
}
.customerSec .quoteImg img{
    width: 90px;
}
.customerSec .cutomersImg {
    max-width: 500px;
    margin: auto;
}
.customerSec .cutomersImg .imgDiv {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    padding: 4px;
    background: white;
    cursor: pointer;
    margin: auto;
    transition:  all 0.3s;
}

.scale1-4 {
    transform: scale(1.25);
    /* background: #000 !important; */
    transition: all 0.3s;
}
.customerSec .cutomersImg img{
    width: 100%;
    height: 100%;
}
.customerSec .col1 .ellipse {
    position: absolute;
    bottom: -117px;
    left: -86px;
    width: 340px;
    opacity: 0.8;
}

.blockchainSec {
    padding: 100px 0 70px 0;
    position: relative;
}
.blockchainSec .effect {
    position: absolute;
    top: 320px;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;
}
.pr {
    position: relative;
}
.z-1 {
    z-index: 1;
}
.darkMode .blockchainSec h2{
    color: white;
}
.blockchainSec h2{
    color: black;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 30px;
}
.blockchainSec .blockCard {
    box-shadow: 0 0 20px rgb(8 0 17 / 25%);
    border-radius: 24px;
    position: relative;
    margin-bottom: 30px;
    transition: all 0.3s;
    top: 0px;
    background-color: #fff;
}
.blockchainSec .blockCard:hover {
    transition: all 0.3s;
    position: relative;
    top: -10px;
    box-shadow: 0px 11px 20px -1px rgb(8 0 17 / 25%);
}
.blockchainSec .blockCard img{
    max-width: 100%;
}
.blockchainSec .blockCard .content{
    padding: 22px;
    min-height: 150px;
}
.blockchainSec .blockCard h6{
    color: #333333;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 4px;
}
.blockchainSec .blockCard p{
    margin: 0;
    color: #33333395;
}
.blockchainSec .developmentBtn{
    position: absolute;
    background-color: white;
    padding: 8px 15px;
    border-radius: 0;
    top: 35px;
    left: 35px;
}
.blockchainSec .seeAllDiv {
    text-align: center;
    margin-top: 40px;
    width: max-content;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
.blockchainSec .seeAllDiv .shadow{
    
    position: absolute;
    left: -3px;
    right: -3px;
    bottom: -3px;
    top: -3px;
    z-index: -1;
    border-radius: 20px;
    background: linear-gradient(90deg, #6671ee 0%, #1FCBFF 100%);
    filter: blur(7px);

}


.getInTouchSec {
    padding: 30px 50px;
    background: linear-gradient(-90deg, #5C0CEC 0%, #1FCBFF 100%);
    border-radius: 10px;
    margin: 50px 0 100px 0;
    position: relative;
    z-index: 1;
}

.getInTouchSec .contentMain {
    align-items: center;
}
.getInTouchSec .col1{
    display: flex;
    align-items: center;
}
.getInTouchSec .col1 img{
    width: 100px;
    margin-right: 20px;
}
.getInTouchSec .col1 h3{
   font-weight: 700;
}
.getInTouchSec .col2 {
    text-align: right;
}
.getInTouchSec .col2 .getInTouchBtn{
    padding: 8px;
    width: 176px;
    background-color: white;
    border: 2px solid white;
    border-radius: 20px;
    font-weight: 500;
    transition: all 0.3s;
}
.getInTouchSec .col2 .getInTouchBtn:hover{
        border: 2px solid white;
        background: #5572f2;
        color: white;
        transition: all 0.3s;
}


footer {
    position: relative;
    padding-top: 20px;
}
footer .effect {
    position: absolute;
    right: -700px;
    bottom: -500px;
}
footer .effect1 {
    position: absolute;
    left: -700px;
    bottom: -500px;
}
footer .col1 img {
    max-width: 165px;
}
footer.darkMode .col1 p {
    color: white;
}
footer .col1 p {
    font-size: 18px;
    max-width: 320px;
    color: #222A41;
    margin-top: 20px;
}
footer.darkF.lightMode .col1 p {
    color: #030303;
}
footer.darkF .col1 p {
    font-size: 18px;
    max-width: 320px;
    color: #fff;
    margin-top: 20px;
}
footer .col2 {
    text-align: right;
}
footer .col2 .footerNavMain {
    padding-bottom: 30px;
    background: linear-gradient( to left, #5C0CEC 0%, #1FCBFF 100% ) left bottom transparent no-repeat;
    background-size: 100% 2px;
    margin-left: 80px;
}
footer.darkF .col2 .footerNavMain {
    padding-bottom: 30px;
    background: linear-gradient( to left, #5C0CEC 0%, #EC5623 100% ) left bottom transparent no-repeat;
    background-size: 100% 2px;
    margin-left: 80px;
}
footer .col2 .footerNavMain .footerNav{
    max-width: 400px;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
}
footer.darkMode .col2 .footerNavMain .footerNav a{
    color: white;
}
footer.darkMode .col2 .footerNavMain .footerNav a:hover{
    color: white;
    opacity: 0.8;
}
footer .col2 .footerNavMain .footerNav a{
    color: #707070;
    cursor: pointer;
}
footer .col2 .footerNavMain .footerNav a:hover{
    color: #707070;
    opacity: 0.8;
    text-decoration: none;
}
footer.darkF .col2 .footerNavMain .footerNav a{
    color: #EC5623;
    cursor: pointer;
}
footer.darkF .col2 .footerNavMain .footerNav a:hover{
    color: #EC5623;
    opacity: 0.8;
    text-decoration: none;
}
footer .col2 .addressMain {
    margin-top: 20px;
}
footer.darkMode .col2 .addressMain a{
    color: white;
}
footer.darkMode .col2 .addressMain a:hover{
    color: white;
}
footer .col2 .addressMain a{
    color: #6D7783;
}
footer .col2 .addressMain a:hover{
    color: #6D7783;
    text-decoration: none;
    opacity: 0.8;
}
footer.darkF.lightMode .col2 .addressMain a{
    color: #030303;
}
footer.darkF .col2 .addressMain a{
    color: white;
}

footer.darkF.lightMode .col2 .addressMain a:hover{
    color: #030303;
    opacity: 0.8;
}
footer.darkF .col2 .addressMain a:hover{
    color: white;
    text-decoration: none;
    opacity: 0.8;
}
footer .col2 .addressMain a img{
    margin-right: 4px;
}
footer .col2 .addressMain .mail{
    margin-right: 40px;
}
footer .alrightMain {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    padding-top: 30px;
}
footer.darkMode .alrightMain p{
    color: white;
}
footer .alrightMain p{
    font-size: 14px;
    color: #6D7783;
    margin: 0;
}

footer.darkF.lightMode .alrightMain p{
    color: #030303;
}
footer.darkF .alrightMain p{
    font-size: 14px;
    color: #fff;
    margin: 0;
}

.navbar .hoverDrop {
    position: relative;
}
.navbar .hoverDrop:hover .drop{
    display: block;
}
.navbar .hoverDrop .drop{
    display: none;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%,0);
    padding: 10px 20px;
    border-radius: 10px;
    background-color: white;
}
.navbar .hoverDrop .drop ul{ 
    list-style: none;
    padding: 0;
}
.navbar .hoverDrop .drop ul li{ 
    list-style: none;
    padding: 1px 0;
}
.navbar .hoverDrop .drop ul li a{ 
    color: #010010;
    text-decoration: none;
}
.navbar .hoverDrop .drop ul li a:hover{ 
    color: #010010;
    text-decoration: underline;
}

@media(max-width:992px) and (min-width:576px){
    .header .headerRow h2 {
        font-size: 50px;
        height: 100px;
    }
    .whatWeDo h2 {
        font-size: 50px;
    }
    
    .specialServiceSec h2 {
        font-size: 50px;
    }
    .ourProject h2 {
        font-size: 50px;
    }
    .teamSec h2 {
        font-size: 50px;
    }
    .customerSec h2 {
        font-size: 50px;
    }
    
    .blockchainSec h2 {
        font-size: 50px;
    }


}
@media(min-width:992px){
    .navbar-nav {
        max-width: 420px;
        width: 100%;
        justify-content: space-around;
    }
   
    .card3 {
        margin-top: 90px !important;
    }
}
@media(max-width:992px){
    .whatWeDo .col2 {
        top: 0;
    }
    .whatWeDo {
        padding: 30px 40px;
    }
    .whatWeDo .whatWeDoMain {
        text-align: center;
        margin: 0;
    }
    .whatWeDo .whatWeDoRow {
        margin-top: 40px;
    }
    .whatWeDo .WhatBackground {
        display: none;
    }
    .header .headerRow {
        padding-top: 30px;
    }
    
    .header .sigupBtn{
        display: block;
        margin-left: 0;
        margin-top: 20px;

    }
    .ourProject .row1 {
        margin-top: 30px;
    }
}
.para{
    font-family: Courier;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;

    color: #5B5B5B;
}
.font-bold{
    font-weight: bold;
}
.ai-center{
    align-items: center;
}

.modal-content {
    border-radius: 20px !important;
    overflow: hidden;
}
.contactModal {
    padding: 0 !important;
}
.contactModal h1{
    color: #fff;
    font-weight: 700;
    text-align: center;
    margin-bottom: 60px;
    font-size: 45px;
    font-family: TitilliumWeb;
}
.contactModal .callUs {
    color: white;
}
.contactModal .feeFree{
    color: #fff;
    font-size: 18px;
    text-align: center;
}
.contactModal .modal-body {
    padding: 0 !important;
}
.contactModal .modal-dialog {
    max-width: 800px !important;
}
.contactModal .closeIco{
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1;
    width: 27px;
}
.contactModal .heading {
    background: url(./images/contactBg.webp);
    background-size: cover;
    background-position: right;
    padding: 90px 30px 10px 30px;
}
.contactModal .col2 {
    padding: 60px 40px;
    display: flex;align-items: center;
}
.contactModal .col1 {
    padding: 20px 30px;
    background: linear-gradient(0deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
}
.contactModal .mainContent {
    /* padding: 30px; */
    /* background-color: #EEF2FF; */
    background: #04031A;

}
.contactModal .mainContent .contact{
    font-size: 26px;
    color: #fff;
    line-height: 1.2;
    max-width: 200px;
    margin: auto;
    text-align: center;
    margin-bottom: 15px;
    font-family: PoppinsSemiBold;
}

.contactModal .mainContent .socialMain{
    text-align: center;
    margin: 20px 0;
    margin-bottom: 60px;
  }
.contactModal .mainContent .socialMain a{
    text-decoration: none;
    color: transparent;
    margin-right: 8px;
}
.contactModal .mainContent i {
    padding: 0px 10px;
  }
  
 .contactModal .mainContent .fb {
    color: #4867AA;
    cursor: pointer;
    text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4);
    transition: all ease-in-out 150ms;
  }
  
 .contactModal .mainContent .fb:hover {
    margin-top: -10px;
    text-shadow: 0px 16px 10px rgba(0, 0, 0, 0.3);
    transform: translate(0, -8);
}

.contactModal .mainContent .twitter {
    color: #1DA1F2;
    cursor: pointer;
    text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4);
    transition: all ease-in-out 150ms;
  }
  
 .contactModal .mainContent .twitter:hover {
    margin-top: -10px;
    text-shadow: 0px 16px 10px rgba(0, 0, 0, 0.3);
    transform: translate(0, -8);
  }
  
  .contactModal .mainContent .git {
    transition: all ease-in-out 150ms;
    color: transparent;
    background: -webkit-radial-gradient(30% 107%, circle, #ad9b35 0%, #cfbb45 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background: -o-radial-gradient(30% 107%, circle, #ad9b35 0%, #cfbb45 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background: radial-gradient(circle at 30% 107%, #ad9b35 0%, #cfbb45 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background: -webkit-radial-gradient(circle at 30% 107%, #ad9b35 0%, #cfbb45 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: bold;
  }
  
  .contactModal .mainContent .git:hover {
    margin-top: -10px;
    text-shadow: 0px 14px 10px rgba(0, 0, 0, 0.4);
    transform: translate(0, -5);
}
  .contactModal .mainContent i:nth-child(4) {
    color: #fffc00;
    cursor: pointer;
    text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4);
    transition: all ease-in-out 150ms;
  }
  .contactModal .mainContent i:nth-child(4):hover {
    margin-top: -10px;
    text-shadow: 0px 16px 10px rgba(0, 0, 0, 0.3);
    transform: translate(0, -8);
  }


.contactModal .mainContent .contentDiv{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.contactModal .mainContent .contentDiv .imgDiv{
   margin-right: 8px;
}
.contactModal .mainContent .contentDiv .imgDiv img{
    width: 25px;
}
.contactModal .mainContent .contentDiv .contactDiv p{
    color: #707070;
    font-size: 13px;
    margin: 0;
}
.contactModal .mainContent .contentDiv .contactDiv a{
    color: #fff;
    font-weight: bold;
}
.contactModal .form-control{
    border: none;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
}
.contactModal input{
    color: #707070;
    font-size: 13px;
    font-weight: 500;
    height: 45px;
    border-radius: 30px !important;
}
.contactModal select{
    color: #707070;
    font-size: 13px;
    font-weight: 500;
    height: 45px;
    border-radius: 30px !important;
}
.contactModal textarea::placeholder{
    color: #707070;
    font-size: 13px;
    font-weight: 500;
}
.contactModal .sendBtn{
    background-color: #3364FB;
    color: white;
    font-weight: 500;
    padding: 8px 38px;
    border-radius: 20px;
}
.round-30 {
    border-radius: 30px !important;
}


.darkMode .contactPage {
    background: transparent;
}
.contactModal .reqMeetingMain {
    padding: 30px 50px;
}
.contactModal .reqMeetingMain label{
    color: #fff;
    margin-bottom: 6px;
    font-weight: 500;
}
.contactModal .reqMeetingMain .inputDiv .arrDown{
    position: absolute;
    right: 20px;
    top: 40%;
    transform: translate(-50%,0);
}
.contactModal .reqMeetingMain .inputDiv{
    position: relative;
}
.contactModal select option{
    background: #211933;
    color: white;
    border: none;
    padding: 4px;
}
.contactModal select {
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
 }
 
.contactModal .reqMeetingMain .selectTopic{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 27px;
    display: flex;
    color: white;
    padding: 6px;
    font-size: 14px;
    margin-bottom: 1rem;
}
.contactModal .reqMeetingMain ::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
.contactModal .reqMeetingMain .btnMain{
    display: flex;
    text-align: center;
    margin-top: 40px;
    justify-content: center;
}
.contactModal .reqMeetingMain .btnMain button{
    max-width: 150px;
    width: 100%;
}
.contactModal .reqMeetingMain .selectTopic .sBtn.active {
    background: #EC5623;
}
.contactModal .reqMeetingMain .selectTopic .sBtn{
    width: 100%;
    text-align: center;
    border-radius: 30px;
    padding: 8px;
    cursor: pointer;
}
.contactModal .reqMeetingMain .dateM .form-group{
    width: 100%;
}
.contactModal .modal-content{
    box-shadow: 0px 0px 10px 7px rgb(193 60 185 / 25%);
}
.contactModal .reqMeetingMain .dateM{
    display: flex;
}
.contactPage {
    padding: 0 ;
    min-height: 100vh;
    background: #EEF2FF;

}
.contactPage .heading h1{
    color: black;
    font-weight: 700;
}
.contactPage .heading i{
    position: absolute;
    right: 15px;
    top: 15px;
}
.contactPage .heading {
    background: url(./images/contactBg1.webp);
    background-size: cover;
    background-position: left;
    padding: 110px 30px 100px 30px;
}
.darkMode .contactPage .mainContent {
    background-color: transparent;
}

.contactPage .mainContent {
    padding: 50px 0;
    background-color: #EEF2FF;
}
.darkMode .contactPage .mainContent .contact{
    color: white;
}
.contactPage .mainContent .contact{
    font-size: 38px;
    color: black;
    line-height: 1.2;
}
.contactPage .mainContent .contentDiv{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.contactPage .mainContent .contentDiv .imgDiv{
   margin-right: 8px;
}
.contactPage .mainContent .contentDiv .imgDiv img{
    width: 25px;
}
.contactPage .mainContent .contentDiv .contactDiv p{
    color: #707070;
    font-size: 14px;
    margin: 0;
}
.contactPage .mainContent .contentDiv .contactDiv a{
    color: #3364FB;
    font-weight: bold;
    font-size: 18px;
}
.darkMode .contactPage .form-control{
    box-shadow: 0px 0px 23px 0px rgb(86 172 255 / 42%);
    /* padding: 30px; */
    color: white;
    background: rgba(0,0,0,0.4);
}
.contactPage .form-control{
    border: none;
    border-radius: 8px;
}
.contactPage input{
    color: #707070;
    font-size: 13px;
    font-weight: 500;
}
.contactPage textarea::placeholder{
    color: #707070;
    font-size: 13px;
    font-weight: 500;
}
.contactPage .sendBtn{
    background-color: #3364FB;
    color: white;
    font-weight: 500;
    padding: 7px 38px;
    border: 2px solid #3364FB;
    transition: all 0.3s;
    border-radius: 20px;
}
.contactPage .sendBtn:hover{
    background: transparent;
    color: #3364FB;
  
    border: 2px solid #3364FB;
    transition: all 0.3s;
}

.darkMode .mainNavbar.bgColored {
    background: rgba(0, 0, 0, 0.5);
}
.mainNavbar.bgColored {
    background: rgba(255, 255, 255, 0.5);
}
.mainNavbar {
    background: transparent ;
    backdrop-filter: blur(26px);
}

 .demoModal.darkMode {
    background: #010010;
    color: white;
 }
.demoModal {
    padding: 0 !important;
    /* background: linear-gradient(-90deg, #5C0CEC 0%, #1FCBFF 100%); */
    background: linear-gradient(90deg, #bee3ef 0%, #d3c0f3 100%);
}
.demoModal.darkMode .heading h2{
    color: white;
}
.demoModal .heading h2{
    color: #0b42c9;
    font-weight: 700;
    margin-bottom: 0;
}
.demoModal .heading i{
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    color: white;
}
.demoModal .heading {
    /* background: url(./images/contactBg.png);
    background-size: cover;
    background-position: right; */
    /* padding: 90px 30px 10px 30px; */
    padding: 30px;
}
.demoModal .mainContent {
    padding: 0px 30px 30px 30px;
    /* background-color: #EEF2FF; */
}
.demoModal .descBox h6{
    color: black;
    font-weight: 600;
    margin: 0;
    word-break: break-word;
}
.demoModal .descBox {
    box-shadow: 0 0 13px rgb(8 0 17 / 25%);
    border-radius: 15px;
    padding: 20px 10px;
    background: white;
    text-align: center;
    position: relative;
    display: flex;align-items: center;
    justify-content: center;

    height: 100%;

}
 .darkMode .connectWeb3Sec .techCard {
    background: transparent;
}
 .connectWeb3Sec .techCard {
    border-radius: 18px;
    box-shadow: 0px 0px 23px 0px rgb(236 86 35 / 42%);
    padding: 30px;
    text-align: center;
    background: white;
    margin-bottom: 22px;
}
 .connectWeb3Sec .techCard .logoDiv {
    height: 100px;
    max-width: 100px;
    margin: auto;
}
 .connectWeb3Sec .techCard .logoDiv img{
    max-height: 100%;
    max-width: 100%;
}
.darkMode .dInvert {
    filter: invert(1);
}
.darkMode .connectWeb3Sec .techCard .name {
    color: white;
}
 .connectWeb3Sec .techCard .name {
    color: var(--darkColor);
    font-weight: 500;
    margin-top: 30px;
}

.newFooter {
    background: #e54b21;
    color: white;
}
.newFooter .r1 {
    padding-top: 30px;
}
.newFooter .subsRow {
    padding: 30px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.newFooter .subsRow form{
    max-width: 350px;
    margin: auto;
}
.newFooter h4{
    font-size: 20px;
    font-weight: bold;
}
.newFooter h3{
    font-weight: bold;
}
.newFooter ul{
    padding: 0;
    list-style: none;
}
.newFooter ul li {
    padding: 4px 0;
}
.newFooter a {
    color: white;
    opacity: 0.8;
}
.newFooter a:hover {
    color: white;
    opacity: 1;
}
.newFooter p {
    color: white;
    margin-bottom: 0;
}
.newFooter .r2 {
    padding: 16px 0;
    background: #392525;
}
.newFooter .r2 .tos {
    text-align: center;
}
.newFooter .socialMain {
    display: flex;
    justify-content: flex-end;
}
.newFooter .socialMain a{
    margin-left: 7px;
}
.newFooter .socialMain i{
    color: white;
}



@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}
.demoModal.darkMode .mainContent .desc{
    color: white;
    opacity: 0.8;
}
.demoModal .mainContent .desc{
    margin: 0;
    color: #0b42c9;
    font-weight: 500;
}
.demoModal .mainContent .contact{
    font-size: 30px;
    color: black;
    line-height: 1.2;
}
.demoModal .sendBtn{
    background-color: #3364FB;
    color: white;
    font-weight: 500;
    padding: 7px 38px;
    border: 2px solid #fff;
    transition: all 0.3s;
    border-radius: 20px;
    margin: 30px auto 0px auto;   
}
.demoModal .sendBtn:hover{
    background: white;
    color: #3364FB;
  
    border: 2px solid #fff;
    transition: all 0.3s;
}
.pt-86 {
    padding-top: 86px;
}
.pb-50 {
    padding-bottom: 50px;
}
.modal {
    backdrop-filter: blur(3px) ;
}
.modal .modal-dialog {
    min-height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.Main404 {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.div404{
    position: absolute;
    margin-top: 9%;
}
.Main404 h1{
    color: black;
    font-weight: 800;
    font-size: 50px;
}


@media (min-width: 768px){
    .modal-dialog {
        max-width: 720px !important;
    }
}

@media(max-width:768px){
    .header .headerRow .navCard {
        margin-top: 30px;
    }
    .customerSec .customerCard {
        margin: auto;
    }
    .customerSec .quoteImg {
        margin-top: 50px;
    }
    .getInTouchSec .col2 {
        text-align: center;
        margin-top: 20px;
    }

    .whatWeDo h2 {
        font-size: 40px;
    }
    .whatWeDo .col1 {
        top: 0;
        display: flex;
    }
    .whatWeDo .col2 {
        display: flex;
    }
    .whatWeDo .col3 {
        display: flex;
        top: 0;
    }
    .whatWeDo .weDoCard {
        margin-bottom: 1.5rem;
        width: 100%;
        margin-bottom: 1.5rem;
        margin-left: 10px;
        margin-right: 10px;
    }
    .teamSec .imgDiv .content1 {
        height: 59%;
    }
   
}
@media(max-width:350px){
  
    .whatWeDo .weDoCard img {
        height: 60px;
    }
}
@media(max-width:576px){
    .mainNavbar {
        background-color: white !important;
    }
    .darkMode .mainNavbar {
        background-color: #010010 !important;
    }
     .connectWeb3Sec .lang .name {
        margin-right: 7px !important;
        margin-left: 7px !important;
    }
    .teamSec .imgMain .col2 {
        padding: 0;
    }
    .Main404 h1{
        
        font-size: 35px;
    }
    .demoModal .descBox {
        padding: 20px 20px;
    }
    .specialServiceSec .serviceCard .icon {
        margin-right: 15px;
    }
    .ourCompanySec .compDesc {
        font-size: 35px;
    }
    .ourCompanySec .col1 .expNum {
        font-size: 130px;
    }
    .header .sigupBtn {
        margin: auto ;
    }
    .projectCounter {
        padding: 80px 16px;
    }
    .projectCounter .shadowMain .shadow {
        left: 2px;
        right: 2px;
        bottom: 2px;
        top: 2px;
        filter: blur(14px);
    }
    .projectCounter .counterMain .countCard {
        width: 100%;
    }
    .projectCounter .counterMain .countCard h3 {
        font-size: 50px;
    }

    .projectCounter1 {
        padding: 80px 16px;
    }

    .projectCounter1 .counterMain .countCard {
        width: 100%;
        max-width: 222px;
        justify-content: flex-start;
    }
    .projectCounter1 .counterMain {
        justify-content: center;
    }
  
    .teamSec .imgDiv {
        /* max-height: 164.2px; */
        max-height: auto;
    }
    .ourProject .projectCardMain{
        width: 160px;
    }
    .ourProject .projectCard img {
        max-width: 110px;
    }
    .teamSec .imgMain {
        flex-direction: column;
    }
    .teamSec .imgMain .col1 {
        display: flex;
        top: 0;
        padding: 0;
        justify-content: center;
    }
    .teamSec .imgMain .col2 {
        display: flex;
        justify-content: center;
    }
    .contactModal .mainContent {
        padding-left: 15px;
        padding-right: 15px;
    }
    .customerSec .slick-next {
        transform: translate(-6px, -50%);
    }
    .customerSec .slick-prev {
        transform: translate(6px, -50%);
    }
    .customerSec .cutomersImg {
        padding: 0 10px;
    }
    .customerSec .cutomersImg .imgDiv {
        margin-left: auto;
        margin-right: auto;
        width: 90px;
        height: 90px;
    }
    .teamSec .imgDiv .content1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .teamSec .imgDiv .content1 p {
        font-size: 13px;
    }
    .teamSec .imgDiv .content1 {
        height: 100%;
    }
    .teamSec .imgDiv:hover .content1 a {
        padding:0 7px;
    }
    .teamSec .imgDiv:hover .content1 a i {
        font-size: 14px;
    }
    .header .headerRow h1 {
        height: 80px;
    }
    .getInTouchSec .col1 h3 {
        font-size: 21px;
    }
    .getInTouchSec .col1 img {
        margin-right: 15px;
    }
    .getInTouchSec .col1 img {
        width: 75px;
        height: auto;
    }
    .getInTouchSec {
        padding: 30px 20px;
    }
    .blockchainSec h2 {
        font-size: 40px;
    }
    .customerSec {
        padding: 70px 0;
    }
    .teamSec h2 {
        font-size: 40px;
    }
    .customerSec h2 {
        font-size: 40px;
    }
    .teamSec .content {
        width: 100%;
        margin-bottom: 40px !important;
    }
    .whatWeDo {
        padding: 30px 10px;
    }
    .header .headerRow h1 {
        font-size: 40px;
    }
   
    .specialServiceSec h2 {
        font-size: 40px;
    }
    .ourProject h2 {
        font-size: 40px;
    }
    .specialServiceSec .element1 {
        bottom: unset;
        top: 37%;
    }
    .ourProject .projectCard {
        margin-bottom: 20px;
    }
    /* .ourProject .row2 {
        margin-top: 0;
        margin-bottom: 0;
    } */
    .teamSec .teamElement {
        position: absolute;
        width: 200px;
        height: auto;
        top: -200px;
    }
    .teamSec .imgDiv {
        max-width: 170px;
        width: 100%;
        margin-left: 5px;
        margin-right: 5px;
    }

    footer .col2 .addressMain {
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    footer .col2 .addressMain .mail {
        margin-right: 0;
    }
   
   
}


@media screen and (max-width: 768px) and (min-width: 576px) {

    .projectCounter .counterMain .countCard {
        width: 50%;
    }
    .projectCounter .counterMain .countCard h3 {
        font-size: 50px;
    }
    .projectCounter1 .counterMain .countCard {
        width: 50%;
    }

    .teamSec .imgDiv {
        max-height: 151.28px;
    }
    .ourProject .projectCardMain{
        width: 160px;
    }
    .ourProject .projectCard img {
        max-width: 110px;
    }
    .d-none-sm-md {
        display: none;
    }
    .teamSec .content {
        width: 100%;
    }
    .getInTouchSec {
        padding: 30px 40px;
    }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
    .ourProject .row1{
        justify-content: center;
        width: 660px;
    }
    .ourProject .row3{
        justify-content: center;
        width: 660px;
    }

    .projectCounter .counterMain .countCard {
        width: 50%;
    }
    .projectCounter .counterMain .countCard h3 {
        font-size: 50px;
    }
    .projectCounter1 .counterMain .countCard {
        width: 50%;
    }
  
    .ourProject .projectCardMain{
        width: 220px;
    }
    .teamSec .teamElement {
        width: 230px;
        height: auto;
    }
    .ourProject .row1 {
        justify-content: center;
    }
    .ourProject .row3 {
        justify-content: center;
    }
  
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .ourProject .row1{
        justify-content: center;
        width: 750px;
    }
    .ourProject .row3{
        justify-content: center;
        width: 750px;
    }
    .projectCounter .counterMain .countCard h3 {
        font-size: 53px;
    }
    .projectCounter1 .counterMain .countCard h3 {
        font-size: 40px;
    }
    .teamSec .imgDiv {
        max-height: 158px;
    }
    .ourProject .projectCardMain{
        width: 250px;
    }
    .whatWeDo .WhatBackground {
        right: -24px;
        border-radius: 10px;
        height: 100%;
    }
    .whatWeDo .whatWeDoMain {
        margin-left: 0;
    }
}
@media(max-width:400px){
    .getInTouchSec .col1 h3 {
        font-size: 19px;
    }
  
    .getInTouchSec {
        padding: 30px 10px;
    }
    
}
@media(max-width:768px){
    .newFooter .subsRow {
        text-align: center;
    }
    .newFooter .subsRow .reachOut{
        margin-top: 10px;
    }
    .newFooter h3 {
        margin-bottom: 12px;
    }
    .newFooter .logoDiv {
        margin-bottom: 20px;
    }
    .newFooter .r2 {
        text-align: center;
    }
    .newFooter .r2 .tos {
        margin: 10px 0;
    }
    .newFooter .socialMain {
        justify-content: center;
    }
    .ourProject .row1{
        justify-content: center;
        width: 480px;
    }
    .ourProject .row3{
        justify-content: center;
        width: 480px;
    }
    
    .d-none-md{
        display: none;
    }
    .teamSec .viewBtn {
        margin-top: 20px;
    }
 
    .getInTouchSec {
        margin: 0px 0 60px 0;
    }
    footer .col2 .footerNavMain {
        margin-left: 0;
    }
    footer .col2 .footerNavMain .footerNav {
        margin-right: auto;
    }
    footer .col1 p {
        max-width: 100%;
        margin-bottom: 35px;
    }
    footer .alrightMain {
        margin-top: 20px;
    }
   
    
}
@media(min-width:1200px){
    .whatWeDo .WhatBackground {
        right: -25px;
        /* margin-right: -10px; */
        border-radius: 10px;
    }
}
@media(min-width:576px){
    .ourProject .row1 {
        margin-left: 0;
        margin-right: 0;
    }
    .ourProject .row3 {
        margin-left: 0;
        margin-right: 0;
    }
    .ourProject .row2 {
        margin-left: 0;
        margin-right: 0;
    }
    
}
