@font-face { font-family: "Poppins"; src: local("Poppins") url('../Fonts/Poppins/Poppins-Regular.ttf') format('woff'); font-display: swap;} 
@font-face { font-family: "PoppinsSemiBold"; src: url('../Fonts/Poppins/Poppins-SemiBold.ttf') } 
@font-face { font-family: "TitilliumWeb"; src: url('../Fonts/Titillium_Web/TitilliumWeb-Bold.ttf') } 

:root {
    --darkColor:#010010;
}
.overflow-hidden {
    overflow: hidden;
}
.defiPlatDevPage h1,
.defiPlatDevPage h2,
.defiPlatDevPage h3,
.defiPlatDevPage h4 {
    font-family: TitilliumWeb;
}
.defiPlatDevPage {
    background: #010010;
    font-family: "Poppins";
}
.defiPlatDevPage.lightMode {
    background: transparent;
}
.lightMode .darkNav .nav-link {
    color: var(--darkColor) !important;
}
.defiPlatDevPage .secOne {
    position: relative;
    padding-top: 150px;
    padding-bottom: 80px;
    color: white;
    z-index: 1;
}
.defiPlatDevPage .secOne .effect {
    position: absolute;
    top: 0;
    width: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;
}
.defiPlatDevPage .secOne .effectRight {
    position: absolute;
    top: -300px;
    right: -500px;
    width: 1000px;
    z-index: 0;
}
.defiPlatDevPage .secOne .effectLeft {
    position: absolute;
    bottom: -450px;
    left: -500px;
    width: 1000px;
    z-index: 0;
}
.defiPlatDevPage .secOne h1{
    font-size: 67px;
    font-weight: bold;
    margin-bottom: 20px;
}
.lightMode.defiPlatDevPage .secOne h1 {
    color: var(--darkColor);
}
.lightMode.defiPlatDevPage .secOne p{
    color: var(--darkColor);
}

.defiPlatDevPage .secOne p{
    font-size: 21px;
    color: white;
    margin-bottom: 20px;
    font-family: "Poppins";
}
.btnGradient {
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    background-size: 100% 100%;
    transition: all .3s;
    color: white;
    border: none;
    padding: 6px 10px;
    border-radius: 18px;
    outline: 2px solid transparent;
    font-weight: 500;
}
.btnGradient:hover {
    background-size: 0% 100%;
    outline: 2px solid #EC5623;
    transition: all .3s;
}
.btnGradient:focus {
    outline: 2px solid #EC5623;
    transition: all .3s;
}
.lightMode .btnGradient:hover {
    background-size: 0% 100%;
    outline: 2px solid #EC5623;
    color: black;
    transition: all .3s;
}
.btnGradientoutline {
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    background-size: 0% 100%;
    transition: all .3s;
    color: white;
    border: none;
    padding: 6px 10px;
    border-radius: 18px;
    outline: 2px solid #EC5623;
    font-weight: 500;
}

.btnGradientoutline:hover {
    outline: 2px solid transparent;
    background-size: 100% 100%;
    transition: all .3s;
}

.btnGradientoutline:hover {
    outline: 2px solid transparent;
    background-size: 100% 100%;
    transition: all .3s;
}
.lightMode .btnGradientoutline:hover {
    outline: 2px solid transparent;
    background-size: 100% 100%;
    transition: all .3s;
    color: white;
}
.btnGradientoutline:focus {
    outline: 2px solid #EC5623 !important;
}
.lightMode .btnGradientoutline {
    color: var(--darkColor);
}
.defiPlatDevPage .secTwo{
    padding: 70px 0;
    color: white;
}
.defiPlatDevPage .secTwo .col:nth-child(1){
    padding: 20px;
    background: #734CFD;
    text-align: center;
}
.defiPlatDevPage .secTwo .col:nth-child(2){
    padding: 20px;
    background: #EC5623;
    text-align: center;
}
.defiPlatDevPage .secTwo .col:nth-child(3){
    padding: 20px;
    background: #F4318D;
    text-align: center;
}
.defiPlatDevPage .secTwo .count{
    font-weight: bold;
    font-size: 40px;
}
.defiPlatDevPage .secTwo .text {
    font-size: 12px;
    margin-top: -5px;
}

.defiPlatDevPage.lightMode .secThree {
    color: var(--darkColor);
}
.defiPlatDevPage .secThree {
    padding-top: 120px;
    padding-bottom: 50px;
    color: white;
    text-align: right;
    position: relative;
    z-index: 0;
}
.defiPlatDevPage .secThree .effect {
    position: absolute;
    top: 0;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;
}
.defiPlatDevPage.lightMode .secThree h1{
    color: var(--darkColor);
}
.defiPlatDevPage .secThree h1{
    font-size: 67px;
    font-weight: bold;
    margin-bottom: 20px;
}
.defiPlatDevPage.lightMode .secThree p{
    color: var(--darkColor);
}
.defiPlatDevPage .secThree p{
    font-size: 22px;
    color: white;
    margin-bottom: 20px;
    text-align: right;
    max-width: 528px;
    margin-left: auto;
}

.defiPlatDevPage .partnerSec {
    position: relative;
    padding: 50px 0;
}
.defiPlatDevPage .partnerSec .effect {
    position: absolute;
    top: 0;
    width: 70%;
    left: 50%;
    top: -350px;
    transform: translate(-50%,0);
    z-index: 0;
}
.pr {
    position: relative;
}
.defiPlatDevPage.lightMode .partnerSec h1{
    color: var(--darkColor);
}
.defiPlatDevPage.lightMode .partnerSec .row img {
    filter: invert(1);

}
.defiPlatDevPage .partnerSec h1{
    font-weight: bold;
    font-size: 60px;
    color: white;
    text-align: center;
}
.defiPlatDevPage .secFour {
    position: relative;
    padding: 70px 0;   
    margin: 50px 0;
    color: white;
    background: linear-gradient(52deg,#EC5623 50%,#F4318D 84%,#F4318D 86%, #5C0CEC 100%);
}

.defiPlatDevPage .secFour h1{
    font-weight: bold;
    font-size: 60px;
    margin-bottom: 15px;
}

.defiPlatDevPage .secFour p{
    font-size: 16px;
    color: white;
}
.defiPlatDevPage .secFour .nftImg{
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    
}


.defiPlatDevPage .secFour button{
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    background-color: white;
    background-size: 0% 100%;
    border-radius: 25px;
    font-weight: 500;
    margin-top: 30px;
    padding: 8px 25px;
    border: none;
    color: #EC5623;
    transition: all 0.3s;
}
.defiPlatDevPage .secFour button:hover{
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    /* background: #3364FB; */
    background-size: 100% 100%;
    box-shadow: 0 1px 14px -2px rgb(62 114 242);
    color: white;
    transition: all 0.3s;
}
.defiPlatDevPage .bestArtistSec {
    padding: 80px 0;
    position: relative;
    z-index: 1;
}
.defiPlatDevPage.lightMode .bestArtistSec h1{
    color: var(--darkColor);
}
.defiPlatDevPage .bestArtistSec h1{
    font-weight: bold;
    font-size: 60px;
    margin-bottom: 30px;
    text-align: center;
}

.defiPlatDevPage .bestArtistSec .bestCard:hover {
    transition: all 0.3s;
    top: -10px;
    
}
.defiPlatDevPage .bestArtistSec .bestCard {
    border: 1px solid #A19E9E;
    border-radius: 18px;
    overflow: hidden;
    transition: all 0.3s;
    position: relative;
    top: 0;
}
.defiPlatDevPage .bestArtistSec .bestCard .bannerDiv {
    padding: 3px;
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
}
.defiPlatDevPage .bestArtistSec .bestCard .content{
    padding: 30px;
    padding-top: 0;
    text-align: center;
}
.defiPlatDevPage .bestArtistSec .bestCard .content .logoDiv{
    max-width: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    margin-top: -25px;
}
.defiPlatDevPage.lightMode .bestArtistSec .bestCard .content .desc{
    color: var(--darkColor);
    font-weight: 500;
}
.defiPlatDevPage .bestArtistSec .bestCard .content .desc{
    font-size: 14px;
    color: white;
    text-align: center;
    margin: 30px 0;
}
/* testimonial */

.show5Line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; /* number of lines to show */
    max-height: 186px;       /* fallback */
}
.cursor-pointer {
    cursor: pointer;
}
.defiPlatDevPage .testmonialSec {
    position: relative;
    padding: 60px 0;
}
.defiPlatDevPage .testmonialSec .effect {
    position: absolute;
    top: 0;
    width: 70%;
    left: 50%;
    top: -550px;
    opacity: 0.8;
    transform: translate(-50%,0);
    z-index: 0;
}
.defiPlatDevPage.lightMode .testmonialSec h1 {
    color: var(--darkColor);
}
.defiPlatDevPage .testmonialSec h1 {
    font-weight: bold;
    font-size: 60px;
    margin-bottom: 30px;
    text-align: center;
}
.defiPlatDevPage.lightMode .testmonialSec .testCard {
    color: var(--darkColor);
    background-color: white;
}
.defiPlatDevPage .testmonialSec .testCard {
    border-radius: 18px;
    box-shadow: 0px 0px 23px 0px rgb(236 86 35 / 42%);
    padding: 30px;
    color: white;
    position: relative;
    top: 0;
    transition:all 0.3s;
}
.defiPlatDevPage .testmonialSec .testCard:hover {
    top: -10px;
    transition:all 0.3s;
}
.defiPlatDevPage .testmonialSec .testCard .starImg {
    width: 150px;
}
.defiPlatDevPage.lightMode .testmonialSec .testCard .desc {
    color: var(--darkColor);
}
.defiPlatDevPage .testmonialSec .testCard .desc {
    font-size: 20px;
    padding-top: 30px;
    padding-bottom: 10px;
    opacity: 0.6;
}
.defiPlatDevPage .testmonialSec .testCard .profileDiv {
    display: flex;
    align-items: center;
    min-height: 72px;
}
.defiPlatDevPage .testmonialSec .testCard .profileDiv img{
   width: 52px;
   height: 52px;
   border-radius: 50%;
}
.defiPlatDevPage .testmonialSec .testCard .profileDiv .name{
    font-size: 18px;
    font-weight: 500;
}
.defiPlatDevPage .testmonialSec .testCard .profileDiv .desig{
    font-size: 15px;
    opacity: 0.6;
}

.defiPlatDevPage .secFive {
    padding-top: 150px;
    padding-bottom: 110px;
    color: white;
    background: url(../images/defiDivBanner.png);
    background-position: center;
    background-size: cover;
    margin-bottom: 50px;
}
.defiPlatDevPage.lightMode .secFive h1{
    color: var(--darkColor);
}
.defiPlatDevPage .secFive h1{
    font-size: 67px;
    font-weight: bold;
    margin-bottom: 20px;
}
.defiPlatDevPage.lightMode .secFive p{
    color: var(--darkColor);
}
.defiPlatDevPage .secFive p{
    font-size: 22px;
    color: white;
    margin-bottom: 20px;
}
.defiPlatDevPage .whatWeDo {
    padding: 0px 40px;
    /* background: linear-gradient(90deg, #5C0CEC 0%, #1FCBFF 100%); */
    /* background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat; */
    border-radius: 10px;
    margin: 130px 0;
    position: relative;
    transition: all 0.3s;
} 
.defiPlatDevPage .secSix {
    position: relative;
    padding: 70px 0;
}
.defiPlatDevPage .secSix .effect {
    position: absolute;
    top: 0;
    width: 70%;
    left: 50%;
    opacity: 1;
    top: -450px;
    transform: translate(-50%,0);
    z-index: 0;
}
.defiPlatDevPage .secSix .projectCard .imgDiv{
    border-radius: 20px;
    padding: 6px;
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
}
.defiPlatDevPage .secSix .projectCard{
    position: relative;
    /* border-radius: 44px; */
    overflow: hidden;
}
.defiPlatDevPage .secSix .projectCardLg .imgDiv{
    border-radius: 20px;
    padding: 6px;
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;

}
.defiPlatDevPage .secSix .projectCardLg{
    position: relative;
    top: -50px;
    border-radius: 20px;
    overflow: hidden;
    width: auto;
    margin: auto;
    margin-bottom: 20px;
}
.defiPlatDevPage.lightMode .secSix h1{
    color: var(--darkColor);
}
.defiPlatDevPage .secSix h1{
    position: relative;
    font-size: 67px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    margin-bottom: 100px;
}
.lightMode .defiPlatDevPage .secSix p{
    color: var(--darkColor);
}
.defiPlatDevPage .secSix p{
    padding: 18px 30px;
    color: white;
    /* background: #F33878; */
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    line-height: 1.1;
}
.defiPlatDevPage .secSix .projectCardLg p{
    padding: 20px 30px;
    font-size: 30px;
}
.defiPlatDevPage.lightMode .teamSec h2 {
    color: var(--darkColor);
}
.defiPlatDevPage.lightMode .teamSec p {
    color: var(--darkColor);

}
.defiPlatDevPage .teamSec h2 {
    color: white;
}
.defiPlatDevPage .teamSec p {
    color: white;
}
.defiPlatDevPage.blockchainSec {
    position: relative;
}
.defiPlatDevPage.blockchainSec .effect {
    position: absolute;
    top: 0;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;
}

.defiPlatDevPage.lightMode.blockchainSec h2 {
    color: var(--darkColor);
}
.defiPlatDevPage.blockchainSec h2 {
    color: white;
}
.defiPlatDevPage.blockchainSec .blockCard {
    background: linear-gradient(0deg,#EC5623 0%,#F4318D 23%, #5C0CEC 100%) 0 100% transparent;
}
.defiPlatDevPage.blockchainSec .blockCard p {
    color: white;
}
.defiPlatDevPage.blockchainSec .blockCard h6 {
    color: white;
    font-weight: 600;
}
.defiPlatDevPage .secSeven {
    padding: 50px 0;
}
.defiPlatDevPage .secSeven .exprerience {
    margin-top: 40px;
}
.darkMode.defiPlatDevPage .secSeven .exprerience h3{
    color: #fff;
}
.defiPlatDevPage .secSeven .exprerience h3{
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 0;
    color: var(--darkColor);
}
.defiPlatDevPage .secSeven .exprerience h4{
    font-size: 40px;
    font-weight: bold;
    color: #EC5623;
}
.defiPlatDevPage .secSeven .content {
    width: max-content;
    margin: 30px auto;
}
.ai-fs {
    align-items: flex-start;
}
.defiPlatDevPage .secSeven .projects {
    margin-bottom: 30px;
}
.defiPlatDevPage.lightMode .secSeven .projects h5{
    color: var(--darkColor);
}
.defiPlatDevPage .secSeven .projects h5{
    font-family: PoppinsSemiBold;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 0px;
}
.defiPlatDevPage .secSeven .projects h5 span{
    color: #EC5623;
}
.defiPlatDevPage.lightMode .secSeven .projects p{
    color: var(--darkColor);
}
.defiPlatDevPage .secSeven .projects p{
    color: #fff;
    font-size: 20px;
}
.defiPlatDevPage .secEight {
    position: relative;
    padding: 70px 0;
}
.defiPlatDevPage .secEight .effect {
    position: absolute;
    top: 0;
    width: 70%;
    left: 50%;
    opacity: 1;
    top: -300px;
    transform: translate(-50%,0);
    z-index: 0;
}
.defiPlatDevPage.lightMode .secEight .eightCard{
    color: var(--darkColor);
}
.defiPlatDevPage.lightMode .secEight .eightCard{
    background: rgba(236, 236, 236, 0.6);
    box-shadow: 0px 0px 23px 0px rgba(46, 46, 46, 0.32);
}
.defiPlatDevPage .secEight .eightCard{
    border-radius: 10px;
    box-shadow: 0px 0px 23px 0px rgb(236 86 35 / 42%);
    padding: 30px;
    color: white;
    background: rgba(4, 3, 26, 0.4);
}
.defiPlatDevPage .secEight .btnGradient{
    border-radius: 10px;
}
.defiPlatDevPage.lightMode .secEight h1{
    color: var(--darkColor);
}
.defiPlatDevPage .secEight h1{
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
}
.defiPlatDevPage.lightMode .secEight h2{
    color: var(--darkColor);
}
.defiPlatDevPage .secEight h2{
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}
.defiPlatDevPage .secEight .row1 {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    padding: 10px;
}
.defiPlatDevPage .secEight .row1 img{
    margin-right: 20px;
}
.defiPlatDevPage .secEight .row1 .head{
    font-size: 20px;
    font-weight: 500;
}
.defiPlatDevPage.lightMode .secEight .row1 .desc{
    color: var(--darkColor);
}
.defiPlatDevPage .secEight .row1 .desc{
    font-size: 14px;
    color: white;
}
 .newsletterSec {
    position: relative;
    z-index: 1;
    padding: 50px 0;
    margin: 50px 0;
    color: white;
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
}
 .newsletterSec .head{
    font-size: 18px;
    font-weight: 500;
}
 .newsletterSec .desc{
    font-size: 14px;
    color: #e3e3e3;
}
 .newsletterSec .col2{
    max-width: 368px;
    margin-left: auto;
    display: flex;
    align-items: center;
}
 .newsletterSec .col2 input:focus{
    border: none;
    outline: none;
}
 .newsletterSec .col2 input{
    height: 100%;
    padding: 10px 20px;
    font-size: 18px;
    max-width: 100%;
    border: none;
}
/* .defiPlatDevPage .newsletterSec .col2 button{
    background: black;
    color: white;
    border: none;
} */
 .newsletterSec .col2 button{
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    background-color: white;
    background-size: 0% 100%;
    /* border-radius: 25px; */
    font-weight: 500;
    /* margin-top: 30px; */
    height: 47.2px;
    padding: 10px 20px;
    border: none;
    color: #EC5623;
    transition: all 0.3s;
    width: max-content;
}
 .newsletterSec .col2 button:hover{
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    /* background: #3364FB; */
    background-size: 100% 100%;
    box-shadow: 0 1px 14px -2px rgb(62 114 242);
    color: white;
    transition: all 0.3s;
}
 .connectWeb3Sec {
    padding: 70px 0;
    color: white;
    position: relative;
}
 .connectWeb3Sec .effect1 {
    position: absolute;
    top: 0;
    width: 90%;
    left: 50%;
    opacity: 0.6;
    top: -300px;
    transform: translate(-50%,0);
    z-index: 0;
}

.lightMode .connectWeb3Sec h1{
    color: var(--darkColor);
}
.lightMode .connectWeb3Sec .title{
    color: var(--darkColor);
}
.lightMode .connectWeb3Sec .lang{
    color: var(--darkColor);
}
 .connectWeb3Sec h1{
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}
 .connectWeb3Sec .title{
    font-size: 22px;
    margin-bottom: 30px;
    text-align: center;
}
 .connectWeb3Sec .bannerImg{
    padding: 10px;
    border-radius: 20px;
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
}
 .connectWeb3Sec .bannerImg img{
    border-radius: 25px;
}
 .connectWeb3Sec .lang{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 40px 0;
}
.darkMode .connectWeb3Sec .lang .name{
    background-color: transparent;
}
 .connectWeb3Sec .lang .name.active{
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    color: white;
}
 .connectWeb3Sec .lang .name{
    padding: 6px 10px;
    width: 140px;
    box-shadow: 0px 0px 23px 0px rgb(236 86 35 / 42%);
    border-radius: 10px;
    text-align: center;
    margin-right: 15px;
    font-weight: 500;
    margin-bottom: 15px;
    background-color: white;
    cursor: pointer;
}
 .connectWeb3Sec .btnMain {
    margin-top: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
}
 .connectWeb3Sec .btnMain button:first-child{
    margin-right: 20px;
}
 .connectWeb3Sec .btnMain button{
    font-size: 22px;
    font-weight: 500;
    padding: 12px;
    max-width: 400px;
    width: 100%;
    border-radius: 10px;
}

.defiPlatDevPage.lightMode .secNine {
    color: var(--darkColor);
}
.defiPlatDevPage .secNine {
    padding: 50px 0;
    padding-top: 80px;
    color: white;
    position: relative;
    z-index: 1;
}
.defiPlatDevPage .secNine .effect1 {
    position: absolute;
    top: 0;
    width: 90%;
    left: 50%;
    opacity: 1;
    top: 450px;
    transform: translate(-50%,0);
    z-index: 0;
}
.defiPlatDevPage.lightMode .secNine h1{
    color: var(--darkColor);
}
.defiPlatDevPage .secNine h1{
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.defiPlatDevPage .secNine .head{
    color: #EC5623;
    font-size: 60px;
    font-weight: bold;
}
.defiPlatDevPage.lightMode .secNine .text{
    color: var(--darkColor);
}
.defiPlatDevPage .secNine .text{
    color: #fff;
    font-size: 20px;
    max-width: 400px;
}

.defiPlatDevPage .secNine .bannerImg{
    padding: 10px;
    border-radius: 20px;
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
}
.defiPlatDevPage .secNine .bannerImg img{
    border-radius: 25px;
}
.defiPlatDevPage.lightMode .secNine button{
    color: var(--darkColor);
}
.defiPlatDevPage .secNine button{
    color: white;
    font-size: 18px;
    margin-top: 30px;
    padding-left: 0;
    font-weight: 500;
}
.defiPlatDevPage.lightMode .secNine .btnGradient{
    color: #fff;
}
.defiPlatDevPage .secNine .btnGradient{
    font-size: 22px;
    font-weight: 500;
    padding: 12px;
    max-width: 400px;
    width: 100%;
    border-radius: 10px;
}

.defiPlatDevPage .secTen {
    padding: 50px 0;
}
.defiPlatDevPage .secTen .exprerience {
    margin-top: 40px;
}
.defiPlatDevPage.lightMode .secTen .exprerience h3{
    color: var(--darkColor);
}
.defiPlatDevPage .secTen .exprerience h3{
    font-size: 70px;
    font-weight: bold;
    margin-bottom: 0;
}
.defiPlatDevPage .secTen .exprerience h4{
    font-size: 70px;
    font-weight: bold;
    color: #EC5623;
}
.defiPlatDevPage.lightMode .secTen .content {
    color: var(--darkColor);
}
.defiPlatDevPage .secTen .content {
    /* width: max-content; */
    max-width: 480px;
    margin: 30px auto;
}
.ai-fs {
    align-items: flex-start;
}
.defiPlatDevPage .secTen .projects {
    margin-bottom: 30px;
    margin-right: 40px;
}
.defiPlatDevPage.lightMode .secTen .projects h5{
    color: var(--darkColor);
}
.defiPlatDevPage .secTen .projects h5{
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 0px;
}
.defiPlatDevPage .secTen .projects h5 span{
    color: #EC5623;
}
.defiPlatDevPage.lightMode .secTen .projects p{
    color: var(--darkColor);
}
.defiPlatDevPage .secTen .projects p{
    color: #fff;
    font-size: 20px;
}
.defiPlatDevPage .secTen .pMain{
    display: flex;
    margin-top: 30px;
    
}

.defiPlatDevPage .secEleven {
    padding: 50px 0;
}
.defiPlatDevPage .secEleven .imgDiv1{
    max-width: 300px;
    margin: auto;
}
.defiPlatDevPage .secEleven .row1{
    position: relative;
}
.defiPlatDevPage .secEleven .row2{
    position: relative;
}
.defiPlatDevPage .secEleven .row1 .content{
    padding: 40px 15px;
    padding-right: 0;
}
.defiPlatDevPage .secEleven .row2 .content{
    padding: 40px 15px;
    padding-left: 0;
}
.defiPlatDevPage .secEleven .row1 .bgEffect{
    position: absolute;
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    width: 50%;
    height: 100%;
    right: 0;
    transform: translate(0, -50%);
    top: 50%;

}
.defiPlatDevPage .secEleven .row2 .bgEffect{
    position: absolute;
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    width: 50%;
    height: 100%;
    left: 0;
    transform: translate(0, -50%);
    top: 50%;

}
.defiPlatDevPage .secEleven h2{
    font-size: 34px;
    color: white;
    font-weight: bold;
}
.defiPlatDevPage .secEleven p{
    font-size: 15px;
    color: white;
    margin-bottom: 0;
}
.defiPlatDevPage .sec12 {
    padding: 50px 0;
    padding-top: 80px;
    position: relative;
}
.defiPlatDevPage .sec12 .effect {
    position: absolute;
    top: 0;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;
}
.defiPlatDevPage.lightMode .sec12 h1{
    color: var(--darkColor);
}
.defiPlatDevPage .sec12 h1{
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    color: white;
}
.defiPlatDevPage .sec12 img{
    position: relative;
}
.defiPlatDevPage .sec12 .pinkBar {
    height: 100px;
    width: 100%;
    position: absolute;
    bottom: 30px;
    background-color: #F4318D;
    z-index: 0;
}
.defiPlatDevPage .sec13 {
    padding: 50px 0;
    padding-top: 80px;
    position: relative;
    z-index: 1;
}
.defiPlatDevPage.lightMode .sec13 h1{
    color: var(--darkColor);
}
.defiPlatDevPage .sec13 h1{
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    color: white;
}
.defiPlatDevPage .sec13 .rowMain {
    padding: 50px 0;
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
}
.defiPlatDevPage .faqSec {
    padding: 50px 0;
    position: relative;
}
.defiPlatDevPage .faqSec .effect {
    position: absolute;
    top: 0;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;
}
.defiPlatDevPage.lightMode .faqSec h1{
    color: var(--darkColor);
}
.defiPlatDevPage .faqSec h1{
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 30px;
    color: white;
}
.defiPlatDevPage .faqSec h6{
    font-size: 25px;
    font-weight: bold;
    color: #9C9898;
    font-size: 25px;

}

/* faq */
.panel-title a{
    color: white !important;
}
.panel-title > a:before {
    float: right !important;
    font-family: FontAwesome;
    content:"\f068";
    padding-right: 5px;
}

.panel-title > a.collapsed:before {
    float: right !important;
    content:"\f067";
}
.panel-title > a:hover, 
.panel-title > a:active, 
.panel-title > a:focus  {
    text-decoration:none;
}
.panel-heading {
    padding: 20px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.lightMode .panel-heading a{
    color: var(--darkColor) !important;
}
.lightMode .panel-heading:hover a{
    color: var(--darkColor) !important;
}
.panel-heading a{
    font-size: 16px;
}
.panel-heading:hover a{
    color:white !important;
}
.lightMode .panel {
    box-shadow: none;
}
.panel {
    /* margin-bottom: 20px !important; */
    background-color: #ffffff;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 15px 16px 13px 8px rgb(4 4 4 / 5%);
}
.faq-ques{
    background-color: transparent;

    border: 1px solid gray;
    border-left: none;
    border-right: none;
    border-radius: 5px;
    
}
.lightMode .faq-ques a{
    color: var(--darkColor);
}
.faq-ques a{
    color: white;
    font-weight: bold;

}
.lightMode .panel-body{
    color: var(--darkColor);
}
.panel-body{
    color: white;
    padding: 12px;
}
.color-white{
    color:white !important;
} 
.defiPlatDevPage.lightMode .specialServiceSec .serviceCard {
    color: var(--darkColor);
}
.defiPlatDevPage .specialServiceSec .serviceCard {
    border-radius: 18px;
    box-shadow: 0px 0px 23px 0px rgb(236 86 35 / 42%);
    padding: 30px;
    color: white;
    background: transparent;
}
.defiPlatDevPage .specialServiceSec .serviceCard:hover {
    box-shadow: 0px 0px 23px 0px rgb(236 86 35 / 42%);
}
.defiPlatDevPage  .specialServiceSec .serviceCard .desc {
    color: white;
}
.defiPlatDevPage.lightMode  .specialServiceSec .serviceCard .desc {
    color: var(--darkColor);
}
.defiPlatDevPage.lightMode  .specialServiceSec h2 {
    color: var(--darkColor);
}
.defiPlatDevPage  .specialServiceSec h2 {
    color: white;
}
.defiPlatDevPage .specialServiceSec .serviceCard .btnDiv .readMore {
    background: transparent;
}
.defiPlatDevPage .specialServiceSec .serviceCard .btnDiv .getDemo {
    background: linear-gradient(270deg,#EC5623 0%, #A142D5 100%) 0 100% transparent no-repeat;
}
.defiPlatDevPage.lightMode .specialServiceSec .serviceCard h6 {
    color: var(--darkColor);
}
.defiPlatDevPage .specialServiceSec .serviceCard h6 {
    color: #fff;
}
.defiPlatDevPage .specialServiceSec .slick-prev:before, .specialServiceSec .slick-next:before {
    color: #EC5623;
    /* padding: 5px 5px;
    border-radius: 10px;
    background-color: white; */
}
.defiPlatDevPage .specialServiceSec {
    padding-top: 100px;
    margin-top: 50px;
}

.defiPlatDevPage .teamSec {
    position: relative;
}
.defiPlatDevPage .teamSec .effect {
    position: absolute;
    top: 0;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;
}
.defiPlatDevPage .sec14 h1{
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    color: white;
}
.defiPlatDevPage .sec14 {
     background: #ee0041;
     padding: 40px;
     text-align: center;
}

.defiPlatDevPage .sec14 button{
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    background-color: white;
    background-size: 0% 100%;
    border-radius: 25px;
    font-weight: 500;
    /* margin-top: 30px; */
    height: 100%;
    padding: 10px 25px;
    border: none;
    color: #EC5623;
    transition: all 0.3s;
}
.defiPlatDevPage .sec14 button:hover{
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    /* background: #3364FB; */
    background-size: 100% 100%;
    box-shadow: 0 1px 14px -2px rgb(62 114 242);
    color: white;
    transition: all 0.3s;
}

.teamSecNew {
    position: relative;
    padding: 50px 0;
}
.lightMode .teamSecNew h2 {
    color: var(--darkColor);
}
.teamSecNew h2 {
    margin-top: 80px;
    position: relative;
    font-size: 67px;
    font-weight: bold;
    margin-bottom: 20px;
}
.lightMode .teamSecNew p{
    color: var(--darkColor);
}
.teamSecNew p{
    color: white;
}
.teamSecNew .slick-slide  {
    transition: all 0.3s;
    filter: opacity(0.5);
}
.teamSecNew .slick-current {
    transform: scale(1.2);
    filter: opacity(1);
    transition: all 0.3s;
    border-radius: 10px;
}
.teamSecNew .effect {
    position: absolute;
    top: 0;
    /* width: 70%; */
    left: -11%;
    opacity: 1;
    top: 50%;
    transform: translate(0,-50%);
    z-index: 0;
    max-height: 600px;
}
.teamSecNew .effect1 {
    position: absolute;
    top: 0;
    width: 70%;
    left: -11%;
    opacity: 1;
    top: 50%;
    transform: translate(0,-50%);
    z-index: 0;
    opacity: 0.5;
    /* max-height: 600px; */
}
.teamSecNew .teamCard {
    padding: 47px 30px;
    position: relative;
    right: -17px;
}


.teamSecNew .imgDiv {

}
.teamSecNew .imgDiv {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    height: 400px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-shadow: 0 0 13px rgb(8 0 17 / 13%);
}
.teamSecNew .slick-prev:before, .slick-next:before {
    color: #7d7cf2;
}
.lightMode .teamSecNew h1{
    color: var(--darkColor);
}
.teamSecNew .imgDiv .content1 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    /* height: 20%; */
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
    background: rgba(0,0,0,0);
    transition: all 0.3s;

  }
  .teamSecNew .imgDiv .content1 h3 {
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
  .teamSecNew .imgDiv .content1 p {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .teamSecNew .imgDiv .content1 a i{
      font-size: 24px;
  }
  .teamSecNew .imgDiv .content1 a {
    text-decoration: none;
    /* background: #002417; */
    color: #fff;
    padding: 5px;
    display: inline-block;
    transition: all 0.3s;
    transition-delay: 0s !important;

  }
  .teamSecNew .imgDiv .content1 a:hover {
      transition: all 0.3s;
      transform: scale(1.2) !important;
        transition-delay: 0s !important;

    
  }
  .lightMode .teamSecNew .imgDiv .content1 h3,
  .lightMode .teamSecNew .imgDiv .content1 p,
  .lightMode .teamSecNew .imgDiv .content1 a {
    
    color: #fff;
  }
  .teamSecNew .imgDiv .content1 h3{
      opacity: 0;
      transition: 0.5s;
      transform: translateY(20px);
      color: rgba(255, 255, 255,0.8);
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 300;
      font-family: poppins;
  }
  .teamSecNew .imgDiv .content1 p,
  .teamSecNew .imgDiv .content1 a {
    opacity: 0;
    transition: 0.5s;
    transform: translateY(20px);
    color: white;
  }
  .teamSecNew .imgDiv:hover .content1 h3 {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
  }
  .teamSecNew .imgDiv:hover .content1 {
    background: rgba(0,0,0,0.6);
    transition: all 0.3s;

  }
  .teamSecNew .imgDiv:hover .content1 p {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.5s;
  }
  .teamSecNew .imgDiv:hover .content1 a {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.7s;
  }


  .coreServiceSec {
    padding: 0px 40px;
    margin: 130px 0;
    position: relative;
    transition: all 0.3s;
}


.lightMode .coreServiceSec h1 {
    color: var(--darkColor);
}
.lightMode .coreServiceSec h2 {
    color: var(--darkColor);
}
.coreServiceSec h2 {
    position: relative;
    font-size: 67px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    margin-bottom: 100px;
}

.coreServiceSec .whatRow {
    align-items: center;
    position: relative;
    z-index: 1;
}
.coreServiceSec .btnMain {
    display: flex;
}
.coreServiceSec .btnMain button {
    max-width: 150px;
    width: 100%;
}

.coreServiceSec .getBtn {
    background-color: white;
    border: 2px solid white;
    padding: 9px;
    max-width: 195px;
    width: 100%;
    border-radius: 20px;
    color: black;
    font-weight: 500;
    margin-top: 20px;
    transition: all 0.3s;
}
.coreServiceSec .getBtn:hover {
    border: 2px solid white;
    background: #5572f2;
    color: white;
    transition: all 0.3s;
}

.lightMode .coreServiceSec .weDoCard {
    background-color: #fff;
}
.coreServiceSec .weDoCard {
    position: relative;
    background-color: #04031A;
   border-radius: 10px;
    text-align: center;   
    padding: 30px 10px; 
    /* margin-bottom: 20px; */
    box-shadow: 0 0 20px rgb(8 0 17 / 25%);
    height: 190px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    top: 0px;
    
}
.coreServiceSec .whatWeOuterCard {
    position: relative;
    transition: all 0.3s;
    top: 0px;
}
.coreServiceSec .whatWeOuterCard:hover {
    transition: all 0.3s;
    top: -10px;
    box-shadow: 0px 11px 20px -1px rgb(8 0 17 / 25%);

}
.lightMode .coreServiceSec .weDoCard p{
    color: black;
}
.coreServiceSec .weDoCard p{
    color: #fff;
    font-weight: 500;  
    margin-bottom: 0;
    margin-top: 5px;
}
.lightMode .coreServiceSec .desc {
    color: var(--darkColor);
}
.coreServiceSec .desc {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px;
}
.coreServiceSec .col1 {
    position: relative;
    top: 60px;
}
.coreServiceSec .col2 {
    position: relative;
    top: -60px;
}
.coreServiceSec .col3 {
    position: relative;
    top: 60px;
}
.coreServiceSec .whatWeOuterCard {
    padding: 5px;
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    border-radius: 10px;
}
.coreServiceSec .whatWeDoRow {
    align-items: center;
    justify-content: flex-end;
}
.coreServiceSec .WhatBackground{
    position: absolute;
    bottom: 0;
    max-width: 100%;
    height: 100%;
}

 .getin2Sec {
    position: relative;
    z-index: 1;
    padding: 50px 0;
    margin: 50px 0;
    color: white;
    background: linear-gradient(70deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
}
 .getin2Sec .linesRight{
    position: absolute;
    max-height: 100%;
    top: 0;
    right: 0;
    opacity: 0.3;
}
 .getin2Sec .linesLeft{
    position: absolute;
    max-height: 100%;
    top: 0;
    left: 0;
    opacity: 0.3;
}
 .getin2Sec .head2{
    font-size: 50px;
    font-weight: bold;
}
 .getin2Sec .col2{
    text-align: right;
}
 .getin2Sec .whiteBtn{
    background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    background-color: white;
    background-size: 0% 100%;
    border-radius: 25px;
    font-weight: 500;
    font-size: 22px;
    padding: 10px 35px;
    border: none;
    color: #EC5623;
    transition: all 0.3s;
}
.jc-sb {
    justify-content: space-between;
}

.defiPlatDevPage .sec29 {
    position: relative;
    padding: 70px 0;
}
.defiPlatDevPage .sec29 .effect {
    position: absolute;
    top: 0;
    width: 70%;
    left: 50%;
    opacity: 1;
    top: -300px;
    transform: translate(-50%,0);
    z-index: 0;
}
.defiPlatDevPage.lightMode .sec29 .eightCard{
    color: var(--darkColor);
}
.defiPlatDevPage.lightMode .sec29 .eightCard{
    /* background: rgba(236, 236, 236, 0.6); */
    /* box-shadow: 0px 0px 23px 0px rgba(46, 46, 46, 0.32); */
}
.defiPlatDevPage .sec29 .eightCard{
    border-radius: 10px;
    /* box-shadow: 0px 0px 23px 0px rgb(236 86 35 / 42%); */
    padding: 30px;
    color: white;
    /* background: rgba(4, 3, 26, 0.4); */
}
.defiPlatDevPage .sec29 .btnGradient{
    border-radius: 10px;
}
.defiPlatDevPage.lightMode .sec29 h1{
    color: var(--darkColor);
    margin-bottom: 0;
}
.defiPlatDevPage .sec29 h1{
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    margin-bottom: 0;
}
.defiPlatDevPage.lightMode .sec29 h2{
    color: var(--darkColor);
}
.defiPlatDevPage .sec29 h2{
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}
.defiPlatDevPage .sec29 .row1 {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    padding: 10px;
    position: relative;
}
.defiPlatDevPage .sec29 .row1:last-child:before {
    content: none;
}
.defiPlatDevPage .sec29 .row1::before {
    position: absolute;
    content: '';
    width: 1px;
    height: 90%;
    left: 25px;
    top: 47px;
    border-left: 2px dashed #ee0041;
}
.defiPlatDevPage .sec29 .row1 img{
    margin-right: 20px;
}
.defiPlatDevPage .sec29 .row1 .head{
    font-size: 20px;
    font-weight: 500;
}
.defiPlatDevPage.lightMode .sec29 .row1 .desc{
    color: var(--darkColor);
}
.defiPlatDevPage .sec29 .row1 .desc{
    font-size: 14px;
    color: white;
}
.defiPlatDevPage.lightMode .sec29 .descrip{
    color: var(--darkColor);
}
.defiPlatDevPage .sec29 .descrip{
    font-size: 16px;
    color: white;
    text-align: center;
    margin-bottom: 10px;
}


@media(min-width:1200px){
    .coreServiceSec .WhatBackground {
        right: -25px;
        border-radius: 10px;
    }
}
@media screen and (min-width:992px) and (max-width:1200px) {
    .defiPlatDevPage .secFour .nftImg {
        max-width: 50%;
    }
    .coreServiceSec .WhatBackground {
        right: -24px;
        border-radius: 10px;
        height: 100%;
    }
   
}
@media(max-width:992px) and (min-width:576px){

    .coreServiceSec h2 {
        font-size: 50px;
    }
     .getin2Sec .head2 {
        font-size: 35px;
    }
}
@media(max-width:992px){
     .getin2Sec .head2 {
        margin-bottom: 20px;
    } 
     .getin2Sec .linesLeft {
        display: none;
    }
     .getin2Sec .col2 {
        text-align: center;
    }
    .teamSecNew .content{
        margin-bottom: 30px;
    }
    .coreServiceSec .btnMain {
        justify-content: center;
    }
    .coreServiceSec .col2 {
        top: 0;
    }
    .coreServiceSec {
        padding: 30px 40px;
    }
    .coreServiceSec .whatWeDoMain {
        text-align: center;
        margin: 0;
    }
    .coreServiceSec .whatWeDoRow {
        margin-top: 40px;
        justify-content: center;
    }
    .coreServiceSec .WhatBackground {
        display: none;
    }
}
@media(max-width:768px){
    
    .coreServiceSec h2 {
        font-size: 40px;
    }
    .coreServiceSec .col1 {
        top: 0;
        display: flex;
    }
    .coreServiceSec .col2 {
        display: flex;
        justify-content: space-between;
    }
    .coreServiceSec .col3 {
        display: flex;
        top: 0;
        justify-content: space-between;
    }
    .coreServiceSec .whatWeOuterCard {
        margin-bottom: 1.5rem;
        width: 48%;
        margin-bottom: 1.5rem;
        /* margin-left: 10px;
        margin-right: 10px; */
    }
}
@media screen and (min-width:768px) and (max-width:992px) {
    .defiPlatDevPage .secSix .projectCardLg {
        /* width: max-content; */
    }
    .defiPlatDevPage .secFour .nftImg {
        max-width: 50%;
    }
    .defiPlatDevPage .secSix .projectCardLg p {
        font-size: 22px;
    }
}
@media screen and (min-width:576px) and (max-width:768px) {
    .teamSecNew .imgDiv {
        height: 260px;
    }
    .teamSecNew h1 {
        font-size: 26px;
    }
    .defiPlatDevPage .secSix .projectCardLg {
        width: max-content;
    }
    .defiPlatDevPage .secSix .projectCard {
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        margin-bottom:10px;
    }
    .defiPlatDevPage .secEleven .row1 .bgEffect {
        top: unset;
        transform: unset;
        bottom: 0;
        width: 100%;
        height: 48%;
    }
    .defiPlatDevPage .secEleven .row2 .bgEffect {
        top: unset;
        transform: unset;
        bottom: 0;
        width: 100%;
        height: 48%;
    }
}
@media screen and (max-width:768px) {
   
    .defiPlatDevPage .secSix .col-md-4 {
        display: flex;
        justify-content: space-between;
    }
    .defiPlatDevPage .secSix .col-md-4 .projectCard{
        max-width: 48%;
    }
    .defiPlatDevPage .secSix .projectCardLg {
        top: 0;
    }
    .defiPlatDevPage .secFour .nftImg {
        width: 100%;
        filter: brightness(0.4);
    } 
    .defiPlatDevPage.lightMode .secEight .eightCard {
        margin-bottom: 20px;
    } 
    .defiPlatDevPage.lightMode .sec29 .eightCard {
        margin-bottom: 20px;
    } 
     .newsletterSec .col2 {
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
    }
     .connectWeb3Sec .btnMain  {
        flex-direction: column;
        align-items: center;
    }
     .connectWeb3Sec .btnMain button:first-child {
        margin-right: 0;
        margin-bottom: 15px;
    }
}
@media screen and (max-width:576px) {
     .getin2Sec .head2 {
        font-size: 30px;
    } 
    .teamSecNew .imgDiv {
        min-height: 400px;
        height: auto;
    }
    .teamSecNew h1 {
        font-size: 28px;
        margin-top: 10px;
    }
    .teamSecNew .slick-current {
        transform: unset;
    }
    .teamSecNew .teamCard {
        right: 0;
        padding: 20px;
    }
    .teamSecNew .col-lg-8 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .defiPlatDevPage .secSix p {
        padding: 10px;
        font-size: 16px;
    }
    .defiPlatDevPage .secSix .projectCardLg p {
        padding: 10px;
        font-size: 20px;

    }
    .defiPlatDevPage .secSix .projectCardLg {
        max-width: 360px;
    }
    .defiPlatDevPage .secSix .projectCard {
        max-width: 360px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom:10px;
    }
  
    .defiPlatDevPage .secOne h1 {
        font-size: 40px;
    }
    .defiPlatDevPage .secTwo .count {
        font-size: 26px;
    }
    .defiPlatDevPage .secThree h1 {
        font-size: 40px;
    }
    .defiPlatDevPage .secThree p {
        font-size: 16px;
    }
    .defiPlatDevPage .partnerSec h1 {
        font-size: 40px;
        margin-bottom: 25px;
    }
    .defiPlatDevPage .secFour h1 {
        font-size: 40px;
    }
    .defiPlatDevPage .bestArtistSec h1 {
        font-size: 40px;
    }
    .defiPlatDevPage .testmonialSec h1 {
        font-size: 40px;
    }
    .defiPlatDevPage .secFive h1 {
        font-size: 40px;
    }
    .defiPlatDevPage .secSix h1 {
        font-size: 40px;
    }
    .defiPlatDevPage .secEight h1 {
        font-size: 40px;
    }
    .defiPlatDevPage .sec29 h1 {
        font-size: 40px;
    }
     .connectWeb3Sec h1 {
        font-size: 40px;
    }
    .defiPlatDevPage .secNine h1 {
        font-size: 40px;
    }
    .defiPlatDevPage .secNine .head {
        font-size: 40px;
    }
    .defiPlatDevPage .secTen .exprerience h3 {
        font-size: 45px;
    }
    .defiPlatDevPage .secTen .exprerience h4 {
        font-size: 45px;
    }
    .defiPlatDevPage .secEleven .row1 .bgEffect {
        display: none;
    }
    .defiPlatDevPage .secEleven .row2 .bgEffect {
        display: none;
    }
    .defiPlatDevPage .secEleven .row1 .content {
        padding: 20px 10px;
    }
    
    .defiPlatDevPage .secEleven .row2 .content {
        padding: 20px 10px;
    }
    .defiPlatDevPage .secEleven .colorCol {
        background: linear-gradient(270deg,#EC5623 0%,#F4318D 48%, #5C0CEC 100%) 0 100% transparent no-repeat;
    }
    .defiPlatDevPage .secEleven .imgDiv1 {
        margin-bottom: 15px;
    }
    .defiPlatDevPage .sec12 h1 {
        font-size: 40px;
    }
    .defiPlatDevPage .sec13 h1 {
        font-size: 40px;
    }
    .defiPlatDevPage .faqSec h1 {
        font-size: 40px;
    }
    .lightMode .faq-ques a {
        font-size: 18px !important;
    }
    
    .coreServiceSec {
        padding: 30px 10px;
    }
}
@media(max-width:350px){
  
    .coreServiceSec .weDoCard img {
        height: 60px;
    }
}